import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnnouncementsService } from 'src/app/services/announcements.service';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncementModel } from 'src/app/models/Announcements/AnnouncementModel';
import { EntitiesByCountry } from 'src/app/models/Announcements/EntitiesByCountry';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export interface DialogData {
  data: string;
}

@Component({
  selector: 'app-create-announcement',
  templateUrl: './create-announcement.component.html'
})

export class CreateAnnouncementComponent implements OnInit {

  loading = false;
  isSubmitted = false;

  addNewAnnouncement: AnnouncementModel = new AnnouncementModel();

  announcementFormGroup: FormGroup;

  public Editor = ClassicEditor;
  EditorConfig = {
    placeholder: '',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'indent',
        'outdent',
        '|',
        'link',
        'blockQuote',
        'insertTable',
        '|',
        'undo',
        'redo'
      ]
    },
    language: (document.documentElement.lang === 'en') ? 'en' : 'ar'
  };

  //////// Sectors field config /////////////////
  showSectoreTree = false;
  sectorLoading = false;
  @ViewChild('sectorInput', { static: false }) sectorInput: ElementRef<HTMLInputElement>;
  @ViewChild('sectorAuto', { static: false }) sectorMatAutocomplete: MatAutocomplete;
  @ViewChild('chipSectorsList', { static: false }) chipSectorsList: MatChipList;
  sectorChipsErrorMsg: string;
  filteredSectors: Observable<{}[]>;
  sectors = [];
  /** The selection for checklist */
  sectorsSelection = new SelectionModel<EntitiesByCountry>(true /* multiple */);
  SECTOR_TREE_DATA: EntitiesByCountry[] = [];
  sectorTreeData: any[];
  sectorsTreeControl = new NestedTreeControl<EntitiesByCountry>(node => node.entities);
  sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
  hasChild = (_: number, node: EntitiesByCountry) => !!node.entities && node.entities.length > 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<CreateAnnouncementComponent>,
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private service: AnnouncementsService) { }

  ngOnInit() {
    this.LoadEntitiesByCountry();
    this.buildForm();
  }

  buildForm() {
    this.announcementFormGroup = this.formBuilder.group({
      announcementType: ['', Validators.required],
      sectors: [this.sectors, this.validateChips],
    });
    this.announcementFormGroup.get('sectors').setValue(null);
    this.announcementFormGroup.get('sectors').statusChanges.subscribe(
      status => {
        this.chipSectorsList.errorState = status === 'INVALID';
        if (!this.sectors.length && this.sectorInput.nativeElement.value === '') {
          this.sectorChipsErrorMsg = this.translate.instant('forms.Required');
        } else {
          this.sectorChipsErrorMsg = this.translate.instant('forms.validSector');
        }
      }
    );
  }

  LoadEntitiesByCountry() {
    this.sectorLoading = true;
    // const textVal = this.announcementFormGroup.get('sectors').value || '';
    this.service.LoadEntitiesByCountry().subscribe(
      data => {
        this.sectorLoading = false;
        this.SECTOR_TREE_DATA = data as EntitiesByCountry[];
        this.sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
        this.sectorsSelection = new SelectionModel<EntitiesByCountry>(true, this.sectors);
        this.sectors.forEach(node => {
          this.sectorsSelection.select(node);
        });
      }, err => {
        console.error(err);
      });
  }


  submitAnnouncement() {
    this.isSubmitted = true;

    // // console.log(this.sectors);
    this.sectors.forEach(sector => {
      this.addNewAnnouncement.cabsIds.push(sector.id);
      // // console.log(sector.id);
    });

    if (!this.sectors.length) {
      this.announcementFormGroup.get('sectors').setErrors({ valid: false });
    }
    this.addNewAnnouncement.text = this.announcementFormGroup.controls.announcementType.value;
    // // console.log(this.addNewAnnouncement);
    if (this.announcementFormGroup.valid) {
      this.loading = true;
      this.service.addAnnouncements(this.addNewAnnouncement).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(true);
        }, err => {
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
      });
    }
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  private _filter(filteritem: any, parentObj, control: string) {
    if (typeof filteritem === 'string' || filteritem instanceof String) {
      const matches = parentObj.filter(obj => obj.name.toLowerCase().includes(filteritem.toLowerCase()));
      if (filteritem !== '') {
        this.announcementFormGroup.get(control).setErrors({ valid: false });
      }
      return matches;
    } else if (filteritem instanceof Object) { }
  }

  remove(obj, parentObj, control): void {
    const index = parentObj.indexOf(obj);
    if (index >= 0) {
      parentObj.splice(index, 1);
      this.announcementFormGroup.get(control).setValue(parentObj);
    }
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  private validateChips(control: FormControl) {
    if (control.value && control.value.length === 0) {
      return {
        validateChipsArray: { valid: false }
      };
    }

    return null;
  }

  // TREE   configrations //////////////////////////////////////////
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    return descendants.every(child => this.sectorsSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    const result = descendants.some(child => this.sectorsSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: EntitiesByCountry): void {
    this.sectorsSelection.toggle(node);
    const descendants = this.sectorsTreeControl.getDescendants(node);
    this.sectorsSelection.isSelected(node)
      ? this.sectorsSelection.select(...descendants)
      : this.sectorsSelection.deselect(...descendants);
  }

  selectedSector(node) {
    this.sectors = [];
    this.sectorsSelection.selected.forEach((element: EntitiesByCountry) => {
      if (!element.entities && !this.isInArray(this.sectors, element)) {
        this.sectors.push(element);
      }
    });
    this.sectorInput.nativeElement.value = '';
    this.announcementFormGroup.get('sectors').setValue(null);
    // if (this.showSectoreTree) {
    //   this.LoadEntitiesByCountry();
    // }
  }

  removeSector(obj): void {
    const index = this.sectors.indexOf(obj);
    if (index >= 0) {
      this.sectors.splice(index, 1);
      this.todoItemSelectionToggle(obj);
    }
  }
  // END TREE   configrations //////////////////////////////////////////


  // dropdowns overlay events
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showSectoreTree = false;
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.showSectoreTree = false;
    }
  }
}
