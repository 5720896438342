import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({ providedIn: 'root' })
export class LookupsService {

    constructor(private http: HttpClient) {
    }
    GetDashboardHeader() {
        return this.http.get(`${Config.apiUrl}/dashboard/GetDashboardHeader`);
    }
    GetDashboardDetails(filter) {
        return this.http.post(`${Config.apiUrl}/dashboard/GetDashboardDetails`, filter);
    }
    LoadEntitiesBySelectedCountry(countryId) {
        return this.http.get(`${Config.apiUrl}/Entity/LoadEntitiesBySelectedCountry?countryId=${countryId}`);
    }
    getCountries() {
        return this.http.get(`${Config.apiUrl}/lookups/GetCountries`);
    }
    GetNationalities() {
        return this.http.get(`${Config.apiUrl}/lookups/GetNationalities`);
    }
    getCities(countryId) {
        return this.http.get(`${Config.apiUrl}/lookups/GetCities/${countryId}`);
    }
    getNationalities() {
        return this.http.get(`${Config.apiUrl}/lookups/getNationalities`);
    }
    GetAbTypes(text) {
        return this.http.get(`${Config.apiUrl}/lookups/GetAbTypes?text=${text}`);
    }
    GetScopes() {
        return this.http.get(`${Config.apiUrl}/lookups/GetScopes`);
    }
    GetMenu() {
        return this.http.get(`${Config.apiUrl}/lookups/GetMenu`);
    }
    GetRoles() {
        return this.http.get(`${Config.apiUrl}/lookups/GetRoles`);
    }
    GetPermissions() {
        return this.http.get(`${Config.apiUrl}/lookups/GetPermissions`);
    }
}
