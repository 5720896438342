import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {CheckoutPaymentComponent} from './checkout-payment/checkout-payment.component';
import { DuePayment } from 'src/app/models/DuePayment/DuePayment';
import { DuePaymentFilterModal } from 'src/app/models/DuePayment/DuePaymentFilterModal';
import { DuePaymentSortItems } from 'src/app/models/DuePayment/DuePaymentSortItems';
import { DuePaymentStatus } from 'src/app/models/DuePayment/DuePaymentStatus';
import { PaymentRequestsService } from 'src/app/services/payment-requests.service';
import {MatSelectionList} from '@angular/material/list';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-due-payments',
  templateUrl: './due-payments.component.html'
})

export class DuePaymentsComponent implements OnInit {
  @ViewChild('allSelected', {static: false}) private allSelected: MatSelectionList;
  dataLoading = false;
  showFilter = false;
  showSearch = false;

  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.Created'), value: DuePaymentSortItems.Created },
    { name: this.translate.instant('general.Status'), value: DuePaymentSortItems.Status },
    {name: this.translate.instant('general.Amount'), value: DuePaymentSortItems.Amount },
  ];

  filterModel: DuePaymentFilterModal = new DuePaymentFilterModal();

  statusItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.WaitingforApproval'), value: DuePaymentStatus.WaitingForApproval },
    { name: this.translate.instant('general.WaitingforPayment'), value: DuePaymentStatus.WaitingForPayment },
    { name: this.translate.instant('general.WaitingYourAction'), value: DuePaymentStatus.WaitingYourAction },
    { name: this.translate.instant('general.ApprovedPaidwithwallet'), value: DuePaymentStatus.PaidWithWallet },
    { name: this.translate.instant('general.ApprovedPaidwithInvoice'), value: DuePaymentStatus.PaidWithInvoice },
    { name: this.translate.instant('general.Declined'), value: DuePaymentStatus.Declined },
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};


  // selection
  duePaymentsSelection: any[];
  totalAmount = 0;

  relatedData: number;
  transactionType: number;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private signalRService: SignalRServiceService,
    private service: PaymentRequestsService
  ) {
    this.signalRService.handleRespondPaymentEvent(this, this.updateList);
    this.service.refreshDuePaymentListSubject.subscribe(data => {
      if (data) {
        this.getAll(null);
      }
    });
  }


  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
  }

  updateList(context) {
    context.getAll(null);
  }

  sortRecords(selected) {
    this.filterModel.sortItem = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }
  statusFilter(selected) {
    this.filterModel.filterItem.status = selected;
    this.getAll(null);
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    // console.log(this.filterModel);
    this.service.ListDuePayments(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as DuePayment;
          // console.log(this.recordsData);
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  onNgModelChange(event) {
    this.caculateAmountPayments(this.duePaymentsSelection);
  }

  caculateAmountPayments(duePaymentsSelection) {
    this.totalAmount = 0;
    duePaymentsSelection.forEach(element => {
      this.totalAmount += element.amount;
    });
  }
  deselectAll() {
   this.allSelected.deselectAll();
  }

  openCheckoutPaymentDialog() {
    const dialogRef = this.dialog.open(CheckoutPaymentComponent, {
      width: '600px',
      data: {
        duePayments: this.duePaymentsSelection,
        totalAmount: this.totalAmount,
        cab: this.recordsData.cabDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.totalAmount = 0;
        this.duePaymentsSelection = [];
      }
    });
  }
}
