import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserComponent } from '../create-user/create-user.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit {
  dataLoading = true;
  actionLoading = false;
  userId: any;
  details: any;

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private service: UsersService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.userId = params.get('id');
      }
    });
    this.getDetails();
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.userId).subscribe(
      data => {
        this.details = data;
        this.dataLoading = false;
      },
      error => {
        this.dataLoading = false;
        // console.log('error');
      });
  }

  changeStatus(status) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.actionLoading = true;
        const obj = {
          id: this.userId,
          userStatus: status
        };
        this.service.updateUserStatus(obj).subscribe(
          data => {
            this.actionLoading = false;
            this.getDetails();
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: this.translate.instant('messages.User updated successfully'),
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok'),
            });
          }, err => {
            this.actionLoading = false;
            console.error(err);
          });
      }
    });
  }

  deleteUser() {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: this.translate.instant('messages.Delete user'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.Yes delete it'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.service.deleteUser(this.userId).subscribe(
          data => {
            this.actionLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: this.translate.instant('messages.User deleted successfully'),
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok'),
            });
            history.go(-1);
            // this.router.navigate(['/admig/users']);
          }, err => {
            this.actionLoading = false;
            console.error(err);
          });
      }
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openUpdateDialog() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '600px',
      data: {
        userId: this.userId,
        accountType: this.details.accountType,
        isMyProfile: this.details.isMyProfile
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }
}
