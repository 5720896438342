import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LookupsFilterModel } from 'src/app/models/lookupsManagement/LookupsFilterModel';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { oneRecord } from 'src/app/models/lookupsManagement/oneRecord';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CreateNationalityConfigurationComponent } from './create-nationality-configuration/create-nationality-configuration.component';

@Component({
  selector: 'app-nationality-configurations',
  templateUrl: './nationality-configurations.component.html'
})
export class NationalityConfigurationsComponent implements OnInit {

  dataLoading = false;
  showSearch = false;

  filterModel: LookupsFilterModel = new LookupsFilterModel();

  // mat table
  pageCount = 0;
  manualPage = 0;
  displayedColumns = [
    this.translate.instant('general.Name in English'),
    this.translate.instant('general.Name in Arabic'),
      'tableOptions'
  ];

  dataSource: oneRecord[] = [];

  selectedNationality: oneRecord = new oneRecord();

  constructor(private lookupManagementService: LookupManagementService,
              private translate: TranslateService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getListNationalities(null);
  }

  getListNationalities(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListNationalities(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.dataSource = data.pageData as oneRecord[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          console.log(data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getListNationalities(null);
      }
    }
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getListNationalities(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getListNationalities(null);
    }
  }

  

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  RemoveNationality(id) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.Yes delete it'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.lookupManagementService.RemoveNationality(id).subscribe(
          data => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            }).then(() => {
              this.getListNationalities(null);
            });
          },
          error => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Error'),
              text: error[0],
              icon: 'warning',
              confirmButtonText: this.translate.instant('messages.ok')
            });
        });
      }
    });
  }

  // open create lang
  openCreateNationalityDialog() {
    const dialogRef = this.dialog.open(CreateNationalityConfigurationComponent, {
      width: '530px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListNationalities(null);
        });
      }
    });
  }

  // open edit Nationality
  openEditNationalityDialog(NationalityId) {
    this.lookupManagementService.GetNationalityDetails(NationalityId).subscribe(
      data => {
        this.selectedNationality = data as oneRecord;
        this.confgiEditDailog(this.selectedNationality);
      }, err => {
        console.error(err);
    });
  }

  confgiEditDailog(selectedNationality) {
    const dialogRef = this.dialog.open(CreateNationalityConfigurationComponent, {
      width: '530px',
      data: selectedNationality
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListNationalities(null);
        });
      }
    });
  }
}