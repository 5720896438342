import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/EntityUser/ListGACUsers`, filter);
  }
  saveUser(user, type) {
    let url;
    if (user.entityUserId) {
      url = '/EntityUser/UpdateUser';
    } else {
      if (type === 1) {
        url = '/EntityUser/CreateGACUser';
      } else {
        url = '/EntityUser/CreateCBUser';
      }
    }
    return this.http.post(`${Config.apiUrl + url}`, user);
  }
  getDetails(id) {
    if (id) {
      return this.http.get(`${Config.apiUrl}/EntityUser/GetEntityUserDetails?userId=${id}`);
    } else {
      return this.http.get(`${Config.apiUrl}/EntityUser/GetEntityUserProfile`);
    }
  }
  deleteUser(id) {
    return this.http.get(`${Config.apiUrl}/EntityUser/DeleteEntityUser?id=${id}`);
  }
  updateUserStatus(obj) {
    return this.http.post(`${Config.apiUrl}/EntityUser/UpdateEntityUserStatus`, obj);
  }
}
