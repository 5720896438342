import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PaymentRequestRecord } from 'src/app/models/paymentRequest/PaymentRequestRecord';
import { PaymentRequestsService } from 'src/app/services/payment-requests.service';
import {MatSelectionList} from '@angular/material/list';
import { SubmitPaymentModel } from 'src/app/models/DuePayment/SubmitPaymentModel';
import { EntityWalletTransactionType } from 'src/app/models/DuePayment/EntityWalletTransactionType';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import {PaymentConfirmationDialogComponent} from 'src/app/features/due-payments/payment-confirmation-dialog/payment-confirmation-dialog.component';


export interface DialogData {
  relatedData: any;
  transactionType: any;
}

@Component({
  selector: 'app-choose-invoices',
  templateUrl: './choose-invoices.component.html'
})
export class ChooseInvoicesComponent implements OnInit {

  @ViewChild('allSelected', {static: false}) private allSelected: MatSelectionList;

  loading = false;

  dataLoading = false;
  isSubmited = false;

  recordData: any = {};

  submitPaymentModel: SubmitPaymentModel = new SubmitPaymentModel();

  // selection
  duePaymentsSelection: any[];
  totalAmount = 0;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ChooseInvoicesComponent>,
    private service: PaymentRequestsService) { }

  ngOnInit() {
    this.GetEntityWalletDetails(this.data.relatedData);
    this.duePaymentsSelection = [];
    this.totalAmount = 0;
  }

  GetEntityWalletDetails(paymentId) {
    this.dataLoading = true;
    this.service.GetEntityWalletDetails(paymentId).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordData = data as PaymentRequestRecord;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  onNgModelChange(event) {
    this.caculateAmountPayments(this.duePaymentsSelection);
  }

  caculateAmountPayments(duePaymentsSelection) {
    this.totalAmount = 0;
    duePaymentsSelection.forEach(element => {
      this.totalAmount += element.amount;
    });
  }
  deselectAll() {
   this.allSelected.deselectAll();
  }

  sendCheckOut() {
    this.submitPaymentModel.totalPaymentAmount = this.totalAmount;
    this.submitPaymentModel.transactionType = EntityWalletTransactionType.FromDuePayment;
    this.submitPaymentModel.paymentMethod = 0;

    this.duePaymentsSelection.forEach(element => {
      this.submitPaymentModel.duePaymentIds.push(element.duePaymentId);
    });
    if (this.submitPaymentModel.duePaymentIds.length > 0) {
      this.loading = true;
      this.service.SubmitPayment(this.submitPaymentModel, []).subscribe(
        data => {
          if (data) {
            this.loading = false;
            this.dialogRef.close(true);
            this.openPaymentConfirmationDialog(this.totalAmount, 0);
          }
        }, error => {
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: error.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
       });
    }
  }

  openPaymentConfirmationDialog(amount, type) {
    const dialogRef = this.dialog.open(PaymentConfirmationDialogComponent, {
      width: '600px',
      data: { amount, type }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.service.refreshDuePaymentListSubject.next(true);
    });
  }
}
