import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Branch } from 'src/app/models/entity/Branch';
import { Representative } from 'src/app/models/entity/Representative';
import { EntityService } from 'src/app/services/entity.service';
import { Entity } from 'src/app/models/entity/Entity';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  create: boolean;
  record: any;
}

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html'
})
export class EntityFormComponent implements OnInit {
  @ViewChild('stepper', {static: false}) stepper;
  entityRecored: Entity = new Entity();
  entityLogo: File;

  loading = false;
  dataLoading = false;
  isUpdateUser = false;
  isSubmited = false;

  pageIndex = 0;
  stepsCount = 3;
  stepperPages = [
    {index: 0, title: this.translate.instant('general.Basic Info'), required: true},
    {index: 1, title: this.translate.instant('general.Branches'), required: false},
    {index: 2, title: this.translate.instant('general.Representatives'), required: true}
  ];

  activeBranchIndex = 0;
  branches: Branch[] = [new Branch()];

  activeRepresentativeIndex = 0;
  representatives: Representative[] = [new Representative()];
  representativesError = false;

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EntityFormComponent>,
    private service: EntityService
  ) { }

  ngOnInit() {
  }

  infoFormNext(data) {
    this.stepper.next();
    this.pageIndex = this.pageIndex + 1;
    this.entityRecored = data.entity;
    this.entityLogo = data.logo;
  }

  saveRecored() {
    this.representativesError = false;
    if (this.representatives.length !== 2 || this.validateRepresentatives()) {
      this.representativesError = true;
      return;
    }
    this.entityRecored.certifiedEntitiesRepresentatives = this.representatives;

    if (this.branches.length === 1 && !this.branches[0].englishName) {
      this.branches = [];
    }
    this.entityRecored.certifiedEntitiesSites = this.branches;

    this.loading = true;
    this.service.AddEntity(this.entityRecored, this.entityLogo).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        console.error(err);
        // this.dialogRef.close();
      });
  }

  ////////////////// Sets list
  openNewBranch() {
    if (this.branches[this.branches.length - 1].englishName) {
      this.branches.push(new Branch());
    }
    this.activeBranchIndex = this.branches.length - 1;
  }
  toggleBranchSet(index) {
    if (this.activeBranchIndex === index) {
      this.activeBranchIndex = undefined;
    } else {
      this.activeBranchIndex = index;
    }
  }
  pushNewBranch(branche) {
    this.branches[this.activeBranchIndex] = branche;
    this.activeBranchIndex = undefined;
    // console.log(this.branches);
  }
  deleteBranch() {
    this.branches.splice(this.activeBranchIndex, 1);
    if (!this.branches.length) {
      this.branches.push(new Branch());
      this.activeBranchIndex = this.branches.length - 1;
    } else {
      this.activeBranchIndex = undefined;
    }
  }
  ///////////////////////////////////////
  openNewRepresentative() {
    if (this.representatives[this.representatives.length - 1].englishFullName) {
      this.representatives.push(new Representative());
    }
    this.activeRepresentativeIndex = this.representatives.length - 1;
  }
  toggleRepresentativeSet(index) {
    if (this.activeRepresentativeIndex === index) {
      this.activeRepresentativeIndex = undefined;
    } else {
      this.activeRepresentativeIndex = index;
    }
  }
  pushNewRepresentative(representative) {
    this.representatives[this.activeRepresentativeIndex] = representative;
    this.activeRepresentativeIndex = undefined;
  }
  deleteRepresentative() {
    this.representatives.splice(this.activeRepresentativeIndex, 1);
    if (!this.representatives.length) {
      this.representatives.push(new Representative());
      this.activeRepresentativeIndex = this.representatives.length - 1;
    } else {
      this.activeRepresentativeIndex = undefined;
    }
  }
  validateRepresentatives() {
    if (this.representatives.length === 2 && this.representatives[1].englishFullName) {
      return false;
    }
    return true;
  }
}
