import { sortList } from './sortList';
import { statusList } from './statusList';
export class CertificateListFilter {
    pageSize: number;
    pageNumber: number;
    sortItems: sortList;
    searchText: string;
    CABId: number;
    certifiedEntityId: number;
    filter = {} as {
        certificateNumber: string;
        status: statusList;
        scopes: string;
        issueDateFrom: string;
        issueDateTo: string;
        expirationDateFrom: string;
        expirationDateTo: string;
    };
}
