import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from 'src/app/models/entity/Entity';
import { EntityService } from 'src/app/services/entity.service';

export interface DialogData {
  record: any;
}

@Component({
  selector: 'app-update-entity-dialog',
  templateUrl: './update-entity-dialog.component.html'
})
export class UpdateEntityDialogComponent implements OnInit {
  entityRecored: Entity = new Entity();
  entityLogo: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UpdateEntityDialogComponent>,
    private service: EntityService
  ) { }

  ngOnInit() {
  }

  saveRecored(data) {
    this.entityRecored = data.entity;
    this.entityLogo = data.logo;
    this.service.EditEntity(this.entityRecored, this.entityLogo).subscribe(
      () => {
        this.dialogRef.close(true);
      }, err => {
        console.error(err);
        // this.dialogRef.close();
      });
  }
}
