export class SearchCertificateModel {
    certifiedEntityId: number;
    ABTypeId: number;
    scopes: any[];
    authorizedOfficerId: number;
    certifiedEntityLogo: string;
    certificateDocument = {} as {
        attachmentId: number;
        groupEntityId: number;
        path: string;
        name: string;
        size: number;
        contentType: string;
    };
    certificateAuthenticationDocument:null;
    certificateNumber: string;
    scopeNames: any[]
    canEdit: boolean;
    canChangeStatus: boolean;
    representives= {} as {
        isAssignedToCertificate: boolean;
        representiveName: string;
        representiveTitle: string;
    }[];
    created: Date;
    issueingDate: Date;
    expirationDate: Date;
    status= {} as {
        text: string;
        value: number;
    };
    certifiedEntity = {} as {
        certificatesCount: number;
        certifiedEntityId: number;
        arabicName: string;
        englishName: string;
        commercialIdentificationNumber: string;
        logoId: null;
        cityId: number;
        countryId: number;
        arabicAddress:  string;
        address: string;
        englishAddress: string;
        email: string;
        logo: string;
        phone: string;
        fax:  string;
        website: string;
        certifiedEntitiesRepresentatives: null;
        certifiedEntitiesSites: null

    };
    cabDetails = {} as {
        entityId: number;
        name: string;
        arabicName: string;
        englishName: string;
        commercialIdentificationNumber: string;
        accreditationCommencementDate: Date;
        certificationExpirationDate: Date;
        cityId : number;
        cityName: string;
        scopeIds: [];
        scopes: [];
        abTypeIds: [];
        abTypes: [];
        email: string;
        phone: string;
        logo: string;
        website: string;
        address: string;
        arabicAddress: string;
        englishAddress: string;
        fax: string;
    };
    branchNames: any[];
}
