import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Branch } from 'src/app/models/entity/Branch';
import { EntityService } from 'src/app/services/entity.service';

export interface DialogData {
  certifiedEntityId: number;
  branches: Branch[];
}

@Component({
  selector: 'app-update-branches-dialog',
  templateUrl: './update-branches-dialog.component.html'
})
export class UpdateBranchesDialogComponent implements OnInit {
  loading = false;

  activeBranchIndex = 0;
  branches: Branch[] = [new Branch()];
  deletedIds = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UpdateBranchesDialogComponent>,
    private service: EntityService
  ) { }

  ngOnInit() {
    this.branches[0].certifiedEntityId = this.data.certifiedEntityId;
    if (this.data.branches && this.data.branches.length) {
      this.branches = JSON.parse(JSON.stringify(this.data.branches));
    }
    console.log(this.branches);
  }

  saveBranches() {
    this.loading = true;
    const branchesData = {
      tobeDeleted: this.deletedIds,
      items: this.branches
    };
    this.service.CEBranchesEdit(branchesData).subscribe(
      () => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
      });
  }

  ////////////////// Sets list
  openNewBranch() {
    if (this.branches[this.branches.length - 1].englishName) {
      const newBranch = new Branch();
      newBranch.certifiedEntityId = this.data.certifiedEntityId;
      this.branches.push(newBranch);
    }
    this.activeBranchIndex = this.branches.length - 1;
    console.log(this.branches);
  }
  toggleBranchSet(index) {
    if (this.activeBranchIndex === index) {
      this.activeBranchIndex = undefined;
    } else {
      this.activeBranchIndex = index;
    }
  }
  pushNewBranch(branche) {
    this.branches[this.activeBranchIndex] = branche;
    this.activeBranchIndex = undefined;
    // console.log(this.branches);
  }
  deleteBranch() {
    if (this.branches[this.activeBranchIndex].certifiedEntitySiteId) {
      this.deletedIds.push(this.branches[this.activeBranchIndex].certifiedEntitySiteId);
    }
    this.branches.splice(this.activeBranchIndex, 1);
    if (!this.branches.length) {
      this.branches.push(new Branch());
      this.activeBranchIndex = this.branches.length - 1;
    } else {
      this.activeBranchIndex = undefined;
    }
  }
}
