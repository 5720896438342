import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateSectorsComponent } from './create-sectors/create-sectors.component';
import { LookupsFilterModel } from 'src/app/models/lookupsManagement/LookupsFilterModel';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { SectorsRow } from 'src/app/models/lookupsManagement/SectorsRow';
import { SectorDetails } from 'src/app/models/lookupsManagement/SectorDetails';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sectors-configuration',
  templateUrl: './sectors-configuration.component.html'
})
export class SectorsConfigurationComponent implements OnInit {

  dataLoading = false;
  showSearch = false;

  filterModel: LookupsFilterModel = new LookupsFilterModel();

  // mat table
  pageCount = 0;
  manualPage = 0;
  displayedColumns = [
      'Name in English',
      'Name in French',
      '# of Specialization',
      'tableOptions'
  ];

  dataSource: SectorsRow[] = [];

  selectedSector: SectorDetails = new SectorDetails();
  constructor(private lookupManagementService: LookupManagementService,
    private translate: TranslateService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getListSectors(null);
  }
  getListSectors(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListSectors(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.dataSource = data.pageData as SectorsRow[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          // // console.log(data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getListSectors(null);
      }
    }
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getListSectors(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getListSectors(null);
    }
  }


  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  RemoveSector(id) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.Yes delete it'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.lookupManagementService.RemoveSector(id).subscribe(
          data => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            }).then(() => {
              this.getListSectors(null);
            });
          },
          error => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Error'),
              text: error[0],
              icon: 'warning',
              confirmButtonText: this.translate.instant('messages.ok')
            });
        });
      }
    });
  }

  // open create sector
  openCreateSectorDialog() {
    const dialogRef = this.dialog.open(CreateSectorsComponent, {
      width: '530px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: 'added successfully',
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListSectors(null);
        });
      }
    });
  }

  // open edit sector
  openEditSectorDialog(sectorId) {
    this.lookupManagementService.GetSectorDetails(sectorId).subscribe(
      data => {
        // console.log('before open', data);
        this.selectedSector = data as SectorDetails;
        this.confgiEditDailog(this.selectedSector );
      }, err => {
        console.error(err);
    });
  }

  confgiEditDailog(selectedSector) {
    const dialogRef = this.dialog.open(CreateSectorsComponent, {
      width: '530px',
      data: selectedSector
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: 'Updated successfully',
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListSectors(null);
        });
      }
    });
  }
}
