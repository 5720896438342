import { SortItems } from './SortItems';
// import { PaymentSlipStatus } from './PaymentSlipStatus';
export class RequestsFilterModel {
    sortItem: SortItems;
    pageSize: number;
    pageNumber: number;
    searchText: string;
    filterItem = {} as {
        status: number;
        from: Date;
        to: Date;
    };
}
