import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Country } from 'src/app/models/lookups/Country';
import { City } from 'src/app/models/lookups/City';
import { Entity } from 'src/app/models/entity/Entity';
import { LookupsService } from 'src/app/services/lookups.service';
import { EntityService } from 'src/app/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-entity-info-form',
  templateUrl: './entity-info-form.component.html'
})
export class EntityInfoFormComponent implements OnInit {
  @Input() create = true;
  @Input() entity: Entity = new Entity();
  @Output() entityData = new EventEmitter<{}>();

  commercialNoLoader = false;
  InfoFormGroup: FormGroup;
  infoFormSubmitted = false;
  countries: Country[] = [];
  cities: City[] = [];
  public websiteReg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

  // image file
  entityLogo: any = {};
  entityLogoSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  // Edit Mode
  entityFormData: Entity = new Entity();
  editFormLoader = false;
  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private service: EntityService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.getCountries();
    this.InfoFormGroup = this.formBuilder.group({
      enName: ['', [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arName: ['', [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      commercialNo: ['', Validators.required, [this.uniqueCENoValidator()]],
      website: ['', [Validators.pattern(this.websiteReg)]],
      email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')
        ]
      ],
      phone: ['', [Validators.pattern('^[+]?[0-9]{11,50}$')]],
      fax: [''],
      country: ['', Validators.required],
      city: ['', Validators.required],
      enAddress: ['', Validators.required],
      arAddress: ['']
    });
    if (this.entity.certifiedEntityId) {
      this.bindDataToUpdateAccount();
    }
  }

  uniqueCENoValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) { this.commercialNoLoader = true; }
      return this.service.CheckCINumber(control.value)
      .pipe(
        map(res => {
          this.commercialNoLoader = false;
          if (!this.create) { return; }
          if (res) {
            return { commercialNoExist: true };
          }
        })
      );
    };
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
        if (!this.create) {
          this.getCities(this.entity.countryId);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  bindDataToUpdateAccount() {
    this.entityLogoSrc = this.entity.logo;
    this.InfoFormGroup.controls.enName.setValue(this.entity.englishName);
    this.InfoFormGroup.controls.arName.setValue(this.entity.arabicName);
    this.InfoFormGroup.controls.email.setValue(this.entity.email);
    this.InfoFormGroup.controls.country.setValue(this.entity.countryId);
    this.InfoFormGroup.controls.city.setValue(this.entity.cityId);
    this.InfoFormGroup.controls.phone.setValue(this.entity.phone);
    this.InfoFormGroup.controls.commercialNo.setValue(this.entity.commercialIdentificationNumber);
    this.InfoFormGroup.controls.website.setValue(this.entity.website);
    this.InfoFormGroup.controls.fax.setValue(this.entity.fax);
    this.InfoFormGroup.controls.enAddress.setValue(this.entity.englishAddress);
    this.InfoFormGroup.controls.arAddress.setValue(this.entity.arabicAddress);
  }

  submitInfoForm() {
    this.infoFormSubmitted = true;
    if (this.InfoFormGroup.valid && this.entityLogoSrc) {
      this.entityFormData = {
        certifiedEntityId: (this.entity.certifiedEntityId) ? this.entity.certifiedEntityId : -1,
        arabicName: this.InfoFormGroup.controls.arName.value,
        englishName: this.InfoFormGroup.controls.enName.value,
        commercialIdentificationNumber: this.InfoFormGroup.controls.commercialNo.value,
        website: this.InfoFormGroup.controls.website.value,
        email: this.InfoFormGroup.controls.email.value,
        phone: this.InfoFormGroup.controls.phone.value,
        fax: this.InfoFormGroup.controls.fax.value,
        countryId: this.InfoFormGroup.controls.country.value,
        cityId: this.InfoFormGroup.controls.city.value,
        englishAddress: this.InfoFormGroup.controls.enAddress.value,
        arabicAddress: this.InfoFormGroup.controls.arAddress.value
      } as Entity;
      this.entityData.emit({
        entity: this.entityFormData,
        logo: this.entityLogo
      });
      if (!this.create) {
        this.editFormLoader = true;
      }
    }
  }

  // File preview with validation
  changeFileListener($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.entityLogo = file;
    } else {
      this.entityLogoSrc = undefined;
      this.entityLogo = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.entityLogoSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      // case 'pdf':
      //   return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      // case 'doc':
      // case 'docx':
      // case 'wpd':
      // case 'tex':
      //   return 'doc';
      // case 'xlsx':
      // case 'xls':
      //   return 'xls';
      // case 'zip':
      // case 'rar':
      //   return 'zip';
      default:
        return false;
    }
  }
}
