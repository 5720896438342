import { DuePaymentSortItems } from './DuePaymentSortItems';
import { DuePaymentStatus } from './DuePaymentStatus';
export class DuePaymentFilterModal {
    sortItem: DuePaymentSortItems;
    pageSize: number;
    pageNumber: number;
    searchText: string;
    filterItem = {} as {
        status: number;
        from: Date;
        to: Date;
    };
}
