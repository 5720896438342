import { Component, OnInit, HostListener, Output, EventEmitter, NgZone } from '@angular/core';
import { User } from 'src/app/models/user/user';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationsSearchModel } from 'src/app/Models/notification/NotificationsSearchModel';
import { Notification } from 'src/app/Models/notification/Notification';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ChooseInvoicesComponent } from 'src/app/features/due-payments/choose-invoices/choose-invoices.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';
  isArabic = false;

  @Output() sidebarTrigger = new EventEmitter();
  notificationNumber: number;
  currentUser = new User();
  userMenu = false;
  shouldShow = false;

  notifLoading = false;
  pageNumber = 0;
  pageSize = 10;
  pageCount: number;
  notificationMenu = false;
  notificationsResult: any = {
    unSeenNotificationsCount: 0,
    totalCount: 0
  };
  notificationsList: Array<Notification> = [];
  NotificationsObject: NotificationsSearchModel = new NotificationsSearchModel();

  constructor(
    public zone: NgZone,
    private router: Router,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private signalRService: SignalRServiceService,
    private snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    public dialog: MatDialog
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getNotifications(false);
    this.signalRService.buildConnection();
    this.signalRService.handleChangeNotificationCountEvent(this, this.updateNotification);

    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(this.language);
    if (this.language === 'en') { this.isArabic = false; }
  }

  ngOnInit() {
  }
  updateNotification(context) {
    context.zone.run(() => {
      context.getNotifications(true);
      context.openSnackBar(context.translate.instant('general.You have a new notification'));
    });
  }
  openSnackBar(msg) {
    this.snackBar.open(msg, '✖', { duration: 10000, horizontalPosition: 'end', verticalPosition: 'bottom' });
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  toggleSearchBar() {
    this.shouldShow = !this.shouldShow;
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openNotificationPanel() {
    this.notificationMenu = !this.notificationMenu;
    this.userMenu = false;
    this.updateNotificationsSeenStatus();
  }
  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getNotifications(false);
    }
  }
  getNotifications(update) {
    if (update) {
      this.pageNumber = 0;
      this.notificationsList = [];
    }
    this.notifLoading = true;
    this.NotificationsObject.pageSize = this.pageSize;
    this.NotificationsObject.pageNumber = this.pageNumber;
    this.notificationsService.GetNotifications(this.NotificationsObject).subscribe(
      result => {
        this.notifLoading = false;
        this.notificationsResult = result;
        this.pageCount = Math.ceil(this.notificationsResult.totalCount / this.pageSize);
        this.notificationsList = this.notificationsList.concat(this.notificationsResult.pageData);
        // console.log(this.notificationsList);
      }, err => {
        this.notifLoading = false;
        console.error(err);
      });
  }
  updateNotificationsSeenStatus() {
    this.notificationsService.SeeNotifications().subscribe(
      result => {
        this.notificationsResult.unSeenNotificationsCount = 0;
      }, err => {
        console.error(err);
      });
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOpenedDrop();
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.closeOpenedDrop();
    }
  }
  closeOpenedDrop() {
    this.userMenu = false;
    this.shouldShow = false;
    this.notificationMenu = false;
  }

  openChooseInvoicesDialog(event, relatedData, transactionType) {
    event.preventDefault();
    console.log(relatedData, transactionType);
    const dialogRef = this.dialog.open(ChooseInvoicesComponent, {
      width: '600px',
      data: {
        relatedData,
        transactionType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.openDialogWithTemplateRef(this.confirmationDialog);
      }
    });
  }
}
