import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { oneRecord } from 'src/app/models/lookupsManagement/oneRecord';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-create-nationality-configuration',
  templateUrl: './create-nationality-configuration.component.html'
})
export class CreateNationalityConfigurationComponent implements OnInit {

  loading = false;

  NationalityFormGroup: FormGroup;

  NationalityModel: oneRecord = new oneRecord();

  NationalityUpdatedModel: oneRecord = new oneRecord();

  constructor(public dialogRef: MatDialogRef<CreateNationalityConfigurationComponent>,
              @Inject(MAT_DIALOG_DATA) public selectedNationality: oneRecord = new oneRecord(),
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private lookupManagementService: LookupManagementService) { }

  ngOnInit() {
    this.buildForms();
  }

  buildForms() {
    this.NationalityFormGroup = this.formBuilder.group({
      NameEng: ['', Validators.required],
      NameFr: ['', [
          Validators.required,
          Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
        ]
      ],
    });

    if (this.selectedNationality != null) {
      this.setValuesForm();
    }
  }

  setValuesForm() {
    this.NationalityFormGroup.controls.NameEng.setValue(this.selectedNationality.englishName);
    this.NationalityFormGroup.controls.NameFr.setValue(this.selectedNationality.arabicName);
  }

  submitNationality() {
    if (this.NationalityFormGroup.valid) {
      this.loading = true;
      if (this.selectedNationality == null) {
        this.createNationality();
      } else {
        this.updatedNationality();
      }
    }
  }

  createNationality() {

    this.NationalityModel.childs = [];

    this.NationalityModel.englishName = this.NationalityFormGroup.controls.NameEng.value;
    this.NationalityModel.arabicName = this.NationalityFormGroup.controls.NameFr.value;
  
    this.lookupManagementService.AddNationality(this.NationalityModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });

  }

  updatedNationality() {
    this.NationalityUpdatedModel.childs = [];

    this.NationalityUpdatedModel.id = this.selectedNationality.id;
    this.NationalityUpdatedModel.englishName = this.NationalityFormGroup.controls.NameEng.value;
    this.NationalityUpdatedModel.arabicName = this.NationalityFormGroup.controls.NameFr.value;

    this.lookupManagementService.UpdateNationality(this.NationalityUpdatedModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }



  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }


  initName(name) {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
