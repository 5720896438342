import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AnnouncementsService } from 'src/app/services/announcements.service';
import { EntitiesByCountry } from 'src/app/models/Announcements/EntitiesByCountry';
import Swal from 'sweetalert2';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { DiscussionService } from 'src/app/services/discussion.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-create-discussion',
  templateUrl: './create-discussion.component.html'
})
export class CreateDiscussionComponent implements OnInit {

  discussionFormGroup: FormGroup;
  loading = false;

  //////// Sectors field config /////////////////
  showSectoreTree = false;
  sectorLoading = false;
  selectedSector = {} as EntitiesByCountry;
  sectors = [];
  /** The selection for checklist */
  sectorsSelection = new SelectionModel<EntitiesByCountry>(true /* multiple */);
  SECTOR_TREE_DATA: EntitiesByCountry[] = [];
  sectorTreeData: any[];
  sectorsTreeControl = new NestedTreeControl<EntitiesByCountry>(node => node.entities);
  sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
  hasChild = (_: number, node: EntitiesByCountry) => !!node.entities && node.entities.length > 0;

  constructor(
    public dialogRef: MatDialogRef<CreateDiscussionComponent>,
    private formBuilder: FormBuilder,
    private serviceAnn: AnnouncementsService,
    private translate: TranslateService,
    private service: DiscussionService
  ) { }

  ngOnInit() {
    this.LoadEntitiesForDiscussions(false);
    this.buildForm();
  }

  buildForm() {
    this.discussionFormGroup = this.formBuilder.group({
      SpecializationIds: [this.sectors, this.validateChips],
    });
    // this.selectSector(this.sectors[0]);
  }

  LoadEntitiesForDiscussions(search) {
    this.sectorLoading = true;
    this.serviceAnn.LoadEntitiesForDiscussions().subscribe(
      data => {
        this.sectorLoading = false;
        this.SECTOR_TREE_DATA = data as EntitiesByCountry[];
        // console.log(data);
        this.sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
      }, err => {
        console.error(err);
      });
  }


  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  saveDiscussion() {
    if (this.sectors.length === 0 && this.discussionFormGroup.get('SpecializationIds').value) {
      this.discussionFormGroup.get('SpecializationIds').setErrors({notValid: true});
    }
    if (this.discussionFormGroup.valid) {
      this.loading = true;
      // // console.log(this.selectedSector.id);
      this.service.CreateConversation(this.selectedSector.id).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(true);
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: this.translate.instant('messages.Discussion added successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          });
        }, err => {
          console.error(err);
          this.dialogRef.close();
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
      });
    }
  }

  selectSector(node) {
    // console.log(node);
    
    this.selectedSector = node;
    this.sectors = [this.selectedSector];
    this.discussionFormGroup.controls.SpecializationIds.setValue(node.name);
  }
  private _filter(filteritem: any, parentObj, control: string) {
    if (typeof filteritem === 'string' || filteritem instanceof String) {
      const matches = parentObj.filter(obj => obj.name.toLowerCase().includes(filteritem.toLowerCase()));
      if (filteritem !== '') {
        this.discussionFormGroup.get(control).setErrors({ valid: false });
      }
      return matches;
    } else if (filteritem instanceof Object) { }
  }

  remove(obj, parentObj, control): void {
    const index = parentObj.indexOf(obj);
    if (index >= 0) {
      parentObj.splice(index, 1);
      this.discussionFormGroup.get(control).setValue(parentObj);
    }
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  private validateChips(control: FormControl) {
    if (control.value && control.value.length === 0) {
      return {
        validateChipsArray: { valid: false }
      };
    }

    return null;
  }
  // TREE   configrations //////////////////////////////////////////
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    return descendants.every(child => this.sectorsSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    const result = descendants.some(child => this.sectorsSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: EntitiesByCountry): void {
    this.sectorsSelection.toggle(node);
    const descendants = this.sectorsTreeControl.getDescendants(node);
    this.sectorsSelection.isSelected(node)
      ? this.sectorsSelection.select(...descendants)
      : this.sectorsSelection.deselect(...descendants);
  }


  removeSector(obj): void {
    const index = this.sectors.indexOf(obj);
    if (index >= 0) {
      this.sectors.splice(index, 1);
      this.todoItemSelectionToggle(obj);
    }
  }
  // END TREE   configrations //////////////////////////////////////////


  // dropdowns overlay events
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showSectoreTree = false;
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.showSectoreTree = false;
    }
  }
}
