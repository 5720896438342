import { Component, OnInit } from '@angular/core';
import { CabsService } from 'src/app/services/cabs.service';
import { MatDialog } from '@angular/material/dialog';
import { GacFormComponent } from 'src/app/features/gac/gac-form/gac-form.component';
import { CAB } from 'src/app/models/cabs/CAB';

@Component({
  selector: 'app-gac',
  templateUrl: './gac.component.html'
})
export class GacComponent implements OnInit {
  dataLoading = true;
  actionLoading = false;
  details: CAB;

  constructor(
    public dialog: MatDialog,
    private service: CabsService
  ) { }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.dataLoading = true;
    this.service.GetEntityProfileDetails().subscribe(
      data => {
        this.dataLoading = false;
        this.details = data as CAB;
        this.details.entitySiteSetting.englishAboutUs = decodeURIComponent(this.details.entitySiteSetting.englishAboutUs);
        this.details.entitySiteSetting.arabicAboutUs = decodeURIComponent(this.details.entitySiteSetting.arabicAboutUs);
      }, err => {
        console.error(err);
        this.dataLoading = false;
        this.details = null;
      });
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(GacFormComponent, {
      width: '600px',
      data: {record: this.details}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }
}
