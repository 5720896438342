import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Country } from 'src/app/models/lookups/Country';
import { City } from 'src/app/models/lookups/City';
import { Representative } from 'src/app/models/entity/Representative';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-entity-representative-form',
  templateUrl: './entity-representative-form.component.html'
})
export class EntityRepresentativeFormComponent implements OnInit {
  repFormGroup: FormGroup;
  nationalities: Country[] = [];
  countries: Country[] = [];
  cities: City[] = [];

  @Input() recored: Representative = new Representative();

  @Output() newRecored = new EventEmitter<Representative>();
  @Output() deleteItem = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  getNationalities() {
    this.lookupsService.getNationalities().subscribe(
      data => {
        this.nationalities = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }
  buildForm() {
    this.getNationalities();
    this.getCountries();
    if (this.recored && this.recored.countryId) {
      this.getCities(this.recored.countryId);
    }
    this.repFormGroup = this.formBuilder.group({
      enName: [this.recored.englishFullName, [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arName: [this.recored.arabicFullName, [
          Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
        ]
      ],
      enTitle: [this.recored.englishTitle, [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arTitle: [this.recored.arabicTitle, [
          Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
        ]
      ],
      nationality: [this.recored.nationalityId, Validators.required],
      country: [this.recored.countryId, Validators.required],
      city: [this.recored.cityId, Validators.required],
      mobile: [this.recored.mobileNumber, [
          Validators.required,
          Validators.pattern('^[+]?[0-9]{11,50}$')
        ]
      ],
      phone: [this.recored.phone, [
          Validators.pattern('^[+]?[0-9]{11,50}$')
        ]
      ],
      email: [this.recored.email, [
          Validators.required,
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')
        ]
      ]
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  submitRecored() {
    if (this.repFormGroup.valid) {
      this.recored.englishFullName = this.repFormGroup.controls.enName.value;
      this.recored.arabicFullName = this.repFormGroup.controls.arName.value;
      this.recored.englishTitle = this.repFormGroup.controls.enTitle.value;
      this.recored.arabicTitle = this.repFormGroup.controls.arTitle.value;
      this.recored.countryId = this.repFormGroup.controls.country.value;
      this.recored.cityId = this.repFormGroup.controls.city.value;
      this.recored.email = this.repFormGroup.controls.email.value;
      this.recored.phone = this.repFormGroup.controls.phone.value;
      this.recored.mobileNumber = this.repFormGroup.controls.mobile.value;
      this.recored.nationalityId = this.repFormGroup.controls.nationality.value;
      this.newRecored.emit(this.recored);
    }
  }

}
