export class User {
    id: string;
    fullName: string;
    email: string;
    username: string;
    password: string;
    token: string;
    role: string;
    profilePicture: string;
    // tslint:disable-next-line: variable-name
    access_token: string;
    permissions: string[];
}
