import { SortEntityUserItems } from './SortEntityUserItems';
import { UserStatus } from './UserStatus';
import { CustomRoles } from './CustomRoles';
export class RepresentativesFilterModel {
    pageNumber: number;
    pageSize: number;
    sortItems: SortEntityUserItems;
    searchText: string;
    filter = {} as {
        status: UserStatus;
        CreationDateFrom: Date;
        CreationDateTo: Date;
        role: CustomRoles;
    };
}
