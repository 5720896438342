import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

// Layouts
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';

import { LoginComponent } from './features/authentication/login/login.component';
import { ResetPasswordComponent } from './features/authentication/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './features/authentication/forget-password/forget-password.component';
import { ChangePasswordComponent } from './features/authentication/change-password/change-password.component';
// import { MyProfileComponent } from './features/my-profile/my-profile.component';

import { UsersComponent } from './features/users/users.component';
import { UserDetailsComponent } from './features/users/user-details/user-details.component';
import { LanguageConfigurationComponent } from './features/configuration/language-configuration/language-configuration.component';
import { CountriesConfigurationComponent } from './features/configuration/countries-configuration/countries-configuration.component';
import { SectorsConfigurationComponent } from './features/configuration/sectors-configuration/sectors-configuration.component';
import { CABDetailsComponent } from './features/cabs/cabdetails/cabdetails.component';
import { CABSComponent } from './features/cabs/cabs.component';
import { EntitiesComponent } from './features/entities/entities.component';
import { EntityDetailsComponent } from './features/entities/entity-details/entity-details.component';
import { PaymentRequestsComponent } from './features/payment-requests/payment-requests.component';
import { DuePaymentsComponent } from './features/due-payments/due-payments.component';
import { DiscussionsComponent } from './features/discussions/discussions.component';
import { CertificatesComponent } from './features/certificates/certificates.component';
import { CertificatesDetailsComponent } from './features/certificates/certificates-details/certificates-details.component';
import { AnnouncementsComponent } from './features/announcements/announcements.component';
import { RepresentativesComponent } from './features/representatives/representatives.component';
import { RegisterComponent } from './features/authentication/register/register.component';
import { SearchCABComponent } from './features/search-cab/search-cab.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { ActivationComponent } from './features/authentication/activation/activation.component';
import { GacComponent } from './features/gac/gac.component';
import { NationalityConfigurationsComponent } from './features/configuration/nationality-configurations/nationality-configurations.component';
import { ABTypesComponent } from './features/configuration/abtypes/abtypes.component';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      { path: '', component: SearchCABComponent },
      { path: 'certficatescan', component: SearchCABComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LoginComponent },
      { path: 'resetpassword/:userid/:code', component: ResetPasswordComponent },
      { path: 'forgetpassword', component: ForgetPasswordComponent},
      { path: 'activation/:userid/:code', component: ActivationComponent },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent},
      { path: 'dashboard', component: DashboardComponent},
      { path: 'searchcab', component: SearchCABComponent },
      {
        path: 'announcements',
        children: [
          { path: '', component: AnnouncementsComponent}
        ]
      },
      { path: 'gac', component: GacComponent},
      {
        path: 'certificates',
        children: [
          { path: '', component: CertificatesComponent},
          { path: ':id', component: CertificatesDetailsComponent},
        ]
      },
      {
        path: 'duepayment',
        children: [
          { path: '', component: DuePaymentsComponent},
          { path: ':id', component: DuePaymentsComponent},
        ]
      },
      {
        path: 'payment',
        children: [
          { path: '', component: PaymentRequestsComponent},
          { path: ':id', component: PaymentRequestsComponent}
        ]
      },
      { path: 'cabprofile', component: CABDetailsComponent},
      {
        path: 'cabs',
        children: [
          { path: '', component: CABSComponent},
          { path: ':id', component: CABDetailsComponent}
        ]
      },
      { path: 'myprofile', component: UserDetailsComponent },
      { path: 'changepassword', component: ChangePasswordComponent},
      {
        path: 'users',
        children: [
          { path: '', component: UsersComponent},
          { path: ':id', component: UserDetailsComponent},
        ]
      },
      {
        path: 'representatives',
        children: [
          { path: '', component: RepresentativesComponent},
          { path: ':id', component: UserDetailsComponent}
        ]
      },
      {
        path: 'entities',
        children: [
          { path: '', component: EntitiesComponent},
          { path: ':id', component: EntityDetailsComponent},
        ]
      },
      {
        path: 'discussions',
        children: [
          { path: '', component: DiscussionsComponent},
          { path: ':id', component: DiscussionsComponent},
        ]
      },
      {
        path: 'config',
        children: [
          { path: '', component: NationalityConfigurationsComponent},
          { path: 'nationailty', component: NationalityConfigurationsComponent},
          { path: 'countries', component: CountriesConfigurationComponent},
          { path: 'abtypes', component: ABTypesComponent},
          { path: 'language', component: LanguageConfigurationComponent},
          { path: 'sectors', component: SectorsConfigurationComponent}
        ]
      },
      { path: '**', redirectTo: '' }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
