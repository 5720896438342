import { Component, ElementRef, OnInit, ViewChild, Inject  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PaymentRequestsService } from 'src/app/services/payment-requests.service';
import { SubmitPaymentModel } from 'src/app/models/DuePayment/SubmitPaymentModel';
import { EntityWalletTransactionType } from 'src/app/models/DuePayment/EntityWalletTransactionType';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import {PaymentConfirmationDialogComponent} from 'src/app/features/due-payments/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { Config } from 'src/app/models/config';


export interface DialogData {
  duePayments: any[];
  totalAmount: number;
  cab: any;
}

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html'
})
export class CheckoutPaymentComponent implements OnInit {

  @ViewChild('content', {static: false}) content: ElementRef;

  loading = false;
  dataLoading = true;

  payWithWallet = true;
  wireTransfer = false;

  paymentMethodType = new FormControl(0);

  // files
  isSubmited = false;
  files = [];

  currentBalance: number;
  maxBalance = false;

  submitPaymentModel: SubmitPaymentModel = new SubmitPaymentModel();

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData,
               private translate: TranslateService,
               public dialog: MatDialog,
               public dialogRef: MatDialogRef<CheckoutPaymentComponent>,
               private service: PaymentRequestsService) { }

  ngOnInit() {
    this.setSubmitModel();
    this.isSubmited = false;
    this.GetCBCurrentBalance();
  }

  setSubmitModel() {
    this.submitPaymentModel.totalPaymentAmount = this.data.totalAmount;
    this.submitPaymentModel.transactionType = EntityWalletTransactionType.FromDuePayment;
  }

  GetCBCurrentBalance() {
    this.service.GetCBCurrentBalance().subscribe(
      data => {
        this.dataLoading = false;
        this.currentBalance = Number(data);
        if (this.currentBalance < this.data.totalAmount) {
          this.maxBalance = true;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  typeChange(event) {
    if (event.value === 0) {
      this.wireTransfer = false;
      this.payWithWallet = true;
      this.submitPaymentModel.paymentMethod = event.value;
      // this.files = [];
      // this.isSubmited = false;
      // if (this.currentBalance < this.data.totalAmount) {
      //   this.maxBalance = true;
      // }
    }
    if (event.value === 1) {
      this.payWithWallet = false;
      this.wireTransfer = true;
      this.submitPaymentModel.paymentMethod = event.value;
      // this.maxBalance = false;
    }
  }

  updateFilesField($event) {
    this.files = $event;
    this.isSubmited = false;
  }
  removeItem(index, item) {
    this.data.duePayments.splice(index, 1);
    this.data.totalAmount = this.data.totalAmount - item.amount;
    if (this.currentBalance < this.data.totalAmount) {
      this.maxBalance = true;
    } else {
      this.maxBalance = false;
    }
    console.log(this.data.totalAmount, item.amount);
  }

  checkEnableToSubmit() {
    if (!this.data.duePayments.length || this.loading || this.dataLoading) {
      return true;
    }
    if (this.paymentMethodType.value === 0 && this.maxBalance) {
      return true;
    }
    if (this.paymentMethodType.value === 1 && !this.files.length) {
      return true;
    }
    return false;
  }

  SubmitPayment() {
    this.submitPaymentModel.paymentMethod = this.paymentMethodType.value;

    this.data.duePayments.forEach(element => {
      this.submitPaymentModel.duePaymentIds.push(element.duePaymentId);
    });
    this.submitPaymentModel.totalPaymentAmount = this.data.totalAmount;

    if (this.submitPaymentModel.paymentMethod === 1) {
      if (this.files.length > 0) {
        this.isSubmited = false;
      } else {
        this.isSubmited = true;
      }
    } else {
      this.isSubmited = false;
    }
    this.loading = true;
    this.service.SubmitPayment(this.submitPaymentModel, this.files).subscribe(
      data => {
        if (data) {
          this.isSubmited = false;
          this.loading = false;
          this.dialogRef.close(true);
          this.openPaymentConfirmationDialog(this.data.totalAmount, this.paymentMethodType.value);
        }
      }, error => {
        Swal.fire({
          title: this.translate.instant('messages.Error'),
          text: error.message,
          icon: 'error',
          confirmButtonText: this.translate.instant('messages.ok')
        });
     });
  }

  openPaymentConfirmationDialog(amount, type) {
    const dialogRef = this.dialog.open(PaymentConfirmationDialogComponent, {
      width: '600px',
      data: { amount, type }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.service.refreshDuePaymentListSubject.next(true);
    });
  }

  printDiv(elem) {
    const lang = localStorage.getItem('language') || 'en';
    const basicUrl = (location.href.includes('localhost')) ? '' : Config.frontUrl;
    let style = basicUrl + '/assets/css/en-US/style.css';
    let dir = 'ltr';
    if (lang === 'ar') {
      style = basicUrl + '/assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    const mywindow = window.open('', 'PRINT', 'height=600,width=800');
    mywindow.document.write('<html dir="' + dir + '"><head>\
      <link rel="stylesheet" type="text/css" href="' + style + '">\
      <title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.onload = () => {
        mywindow.print();
    };
    // mywindow.close();

    return true;
  }

}
