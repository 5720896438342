import { Branch } from './Branch';
import { Representative } from './Representative';
export class Entity {
    certifiedEntityId: number;
    logo: string;
    arabicName: string;
    englishName: string;
    certificatesCount: number;
    commercialIdentificationNumber: string;
    website: string;
    email: string;
    phone: string;
    fax: string;
    countryId: number;
    cityId: number;
    englishAddress: string;
    arabicAddress: string;
    certifiedEntitiesRepresentatives: Representative[];
    certifiedEntitiesSites: Branch[];
}
