import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CabsService } from 'src/app/services/cabs.service';
import { CAB } from 'src/app/models/cabs/CAB';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export interface DialogData {
  record: CAB;
}

@Component({
  selector: 'app-gac-form',
  templateUrl: './gac-form.component.html'
})
export class GacFormComponent implements OnInit {
  loading = false;

  formGroup: FormGroup;
  isSubmitted = false;
  public websiteReg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

  // image file
  logo: any = {};
  logoSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  public Editor = ClassicEditor;
  EditorConfig = {
    placeholder: '',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'indent',
        'outdent',
        '|',
        'link',
        'blockQuote',
        'insertTable',
        '|',
        'undo',
        'redo'
      ]
    },
    language: (document.documentElement.lang === 'en') ? 'en' : 'ar'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<GacFormComponent>,
    private service: CabsService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.logoSrc = this.data.record.logo;
    this.formGroup = this.formBuilder.group({
      englishName: [this.data.record.englishName, [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arabicName: [this.data.record.arabicName, [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      email: [this.data.record.entitySiteSetting.email, [
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
          Validators.required
        ]
      ],
      fax: [this.data.record.entitySiteSetting.fax, Validators.required],
      facebookLink: [this.data.record.entitySiteSetting.facebookLink, Validators.required],
      instagramLink: [this.data.record.entitySiteSetting.instagramLink, Validators.required],
      linkedInLink: [this.data.record.entitySiteSetting.linkedInLink, Validators.required],
      twitterLink: [this.data.record.entitySiteSetting.twitterLink, Validators.required],
      youtubeLink: [this.data.record.entitySiteSetting.youtubeLink, Validators.required],
      phoneNumber: [this.data.record.entitySiteSetting.phoneNumber, Validators.required],
      webSite: [this.data.record.entitySiteSetting.webSite, Validators.required],
      englishAboutUs: [this.data.record.entitySiteSetting.englishAboutUs, Validators.required],
      arabicAboutUs: [this.data.record.entitySiteSetting.arabicAboutUs, Validators.required],
      englishAddress: [this.data.record.entitySiteSetting.englishAddress, Validators.required],
      arabicAddress: [this.data.record.entitySiteSetting.arabicAddress, Validators.required],

      maximumAnonymousSearch: [this.data.record.entitySiteSetting.maximumAnonymousSearch, Validators.required],
      maximumCABSearch: [this.data.record.entitySiteSetting.maximumCABSearch, Validators.required],
      certificationCost: [this.data.record.entitySiteSetting.certificationCost, Validators.required],
      registrationCost: [this.data.record.entitySiteSetting.registrationCost, Validators.required]
    });
  }
  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  // File preview with validation
  changeFileListener($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.logo = file;
    } else {
      this.logoSrc = undefined;
      this.logo = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.logoSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  saveRecored() {
    this.isSubmitted = true;
    if (this.formGroup.valid && this.logoSrc) {
      this.loading = true;
      let formData;
      formData = {
        entityId: this.data.record.entityId,
        arabicName: this.formGroup.controls.arabicName.value,
        englishName: this.formGroup.controls.englishName.value,
        commercialIdentificationNumber: this.data.record.commercialIdentificationNumber,
        accreditationCommencementDate: this.data.record.accreditationCommencementDate.toLocaleString(),
        certificationExpirationDate: this.data.record.certificationExpirationDate.toLocaleString(),
        ABTypeIds: this.data.record.abTypeIds,
        scopeIds: this.data.record.scopeIds,
        email: this.formGroup.controls.email.value,
        countryId: this.data.record.countryId,
        cityId: this.data.record.cityId,
        englishAddress: this.formGroup.controls.englishAddress.value,
        arabicAddress: this.formGroup.controls.arabicAddress.value,
        entitySiteSetting: {
          arabicAboutUs: encodeURIComponent(this.formGroup.controls.arabicAboutUs.value),
          arabicAddress: this.formGroup.controls.arabicAddress.value,
          englishAboutUs: encodeURIComponent(this.formGroup.controls.englishAboutUs.value),
          englishAddress: this.formGroup.controls.englishAddress.value,
          email: this.formGroup.controls.email.value,
          webSite: this.formGroup.controls.webSite.value,
          phoneNumber: this.formGroup.controls.phoneNumber.value,
          fax: this.formGroup.controls.fax.value,
          facebookLink: this.formGroup.controls.facebookLink.value,
          instagramLink: this.formGroup.controls.instagramLink.value,
          linkedInLink: this.formGroup.controls.linkedInLink.value,
          twitterLink: this.formGroup.controls.twitterLink.value,
          youtubeLink: this.formGroup.controls.youtubeLink.value,

          maximumAnonymousSearch: this.formGroup.controls.maximumAnonymousSearch.value,
          maximumCABSearch: this.formGroup.controls.maximumCABSearch.value,
          certificationCost: this.formGroup.controls.certificationCost.value,
          registrationCost: this.formGroup.controls.registrationCost.value
        }
      };
      this.service.save(formData, this.logo).subscribe(
        () => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: this.translate.instant('gac.GAC updated successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          });
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
          // this.dialogRef.close();
        });
    }
  }

  onEditorReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }
}
