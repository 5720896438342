import { sortList } from './sortList';
export class ListFilter {
    pageSize: number;
    pageNumber: number;
    sortItems: sortList;
    searchText: string;
    filter = {} as {
        countries: string;
        cities: number[];
        status: number;
        expirationDateFrom: string;
        expirationDateTo: string;
    };
}
