import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateLanguageComponent } from './create-language/create-language.component';
import { LookupsFilterModel } from 'src/app/models/lookupsManagement/LookupsFilterModel';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { LanguageRow } from 'src/app/models/lookupsManagement/LanguageRow';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-configuration',
  templateUrl: './language-configuration.component.html'
})
export class LanguageConfigurationComponent implements OnInit {

  dataLoading = false;
  showSearch = false;

  filterModel: LookupsFilterModel = new LookupsFilterModel();

  // mat table
  pageCount = 0;
  manualPage = 0;
  displayedColumns = [
      'Name in English',
      'Name in French',
      'tableOptions'
  ];

  dataSource: LanguageRow[] = [];

  selectedLanguage: LanguageRow = new LanguageRow();

  constructor( private lookupManagementService: LookupManagementService,
    private translate: TranslateService,
               public dialog: MatDialog) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getListLanguages(null);
  }

  getListLanguages(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListLanguages(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.dataSource = data.pageData as LanguageRow[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          // console.log(data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getListLanguages(null);
      }
    }
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getListLanguages(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getListLanguages(null);
    }
  }

  // open create lang
  openCreateLanguageDialog() {
    const dialogRef = this.dialog.open(CreateLanguageComponent, {
      width: '530px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getListLanguages(null);
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListLanguages(null);
        });
      }
    });
  }

  // edit
  editLanguageDetails(langId) {
    this.lookupManagementService.GetLanguageDetails(langId).subscribe(
      data => {
        // console.log('before open', data);
        this.selectedLanguage = data as LanguageRow;
        this.confgiEditDailog(this.selectedLanguage);
      }, err => {
        console.error(err);
    });
  }

  confgiEditDailog(selectedLanguage) {
    const dialogRef = this.dialog.open(CreateLanguageComponent, {
      width: '530px',
      data: selectedLanguage
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getListLanguages(null);
        });
      }
    });
  }

  removeLanguage(id) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.Yes delete it'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.lookupManagementService.RemoveLanguage(id).subscribe(
          data => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: '',
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            }).then(() => {
              this.getListLanguages(null);
            });
          },
          error => {
            this.dataLoading = false;
            Swal.fire({
              title: this.translate.instant('messages.Error'),
              text: error[0],
              icon: 'warning',
              confirmButtonText: this.translate.instant('messages.ok')
            });
        });
      }
    });
  }
}
