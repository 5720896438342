import { Component, OnInit , NgZone, Input} from '@angular/core';
import { DiscussionService } from 'src/app/services/discussion.service';
import { ConversationsFilterModal } from 'src/app/models/conversation/ConversationsFilterModal';
import { NewMessageModel } from 'src/app/models/conversation/NewMessageModel';
import { ConversationMessages } from 'src/app/models/conversation/ConversationMessages';
import { ActivatedRoute } from '@angular/router';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
@Component({
  selector: 'app-discussion-details',
  templateUrl: './discussion-details.component.html'
})
export class DiscussionDetailsComponent implements OnInit {
  @Input() canCreate = false;
  filterModel: ConversationsFilterModal = new ConversationsFilterModal();

  recordsData: ConversationMessages = new ConversationMessages();

  dataLoading = false;
  newMesgLoading = false;
  recordsList = [];

  pageNumber = 0;
  pageCount: number;

  messageId: number;

  newMessageModel: NewMessageModel = new NewMessageModel();

  dataObj = {
    data : [],
    id: null
  };

  constructor(  public zone: NgZone,
                private signalRService: SignalRServiceService,
                private service: DiscussionService,
                private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.filterModel.pageSize = 50;
    if (!this.canCreate) {
      this.messageId = null;
    }
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.messageId = Number(params.get('id'));
      }
    });
    this.GetConversationMessages(this.messageId, true);
    this.signalRService.handleNewMessageEvent(this, this.updateItem);

    // if (!this.canCreate) {
    //   this.GetConversationMessages(null, true);
    // } else {
    //   this.GetConversationMessages(this.messageId, true);
    // }
  }
  updateItem(context, id) {
    context.zone.run(() => {
      if (!context.canCreate) {
        context.GetConversationMessages(null, true);
      } else {
        if (context.messageId === id) {
          context.GetConversationMessages(id, true);
        }
      }
    });
  }
  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.GetConversationMessages(this.messageId, false);
    }
  }
  GetConversationMessages(id, update) {
    if (update) {
      this.pageNumber = 0;
      this.recordsList = [];
    }
    this.dataLoading = true;
    this.filterModel.pageNumber = this.pageNumber;
    this.filterModel.ConversationId = id;
    this.newMessageModel.ConversationId = id;

    this.service.GetConversationMessages(this.filterModel).subscribe(
      result => {
        this.dataLoading = false;
        this.recordsData = result as ConversationMessages;
        this.pageCount = Math.ceil(result.totalCount /  this.filterModel.pageSize);
        const newRecords = this.recordsData.pageData as [];
        this.recordsList = this.recordsList.concat(this.recordsData.pageData.reverse());
        // this.recordsList = this.recordsList.reverse();
        if (this.recordsList.length > 0) {
          setTimeout(() => {
            if (this.pageNumber === 0) {
              document.querySelectorAll('.chat-area')[0].scrollTop = document.querySelectorAll('.chat-area > ul')[0].clientHeight;
            }
          }, 200);
        }
        this.newMesgLoading = false;
      }, err => {
        console.error(err);
    });
  }
  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
  send() {
    if (this.newMessageModel.text && !this.newMesgLoading) {
      this.newMesgLoading = true;
      this.service.CreateMessage(this.newMessageModel).subscribe(
        result => {
          // console.log('done');
          this.newMessageModel.text = '';
          this.GetConversationMessages(this.newMessageModel.ConversationId, true);
          if (this.recordsList.length > 0) {
            setTimeout(() => {
              document.querySelectorAll('chat-area')[0].scrollTop = document.querySelectorAll('chat-area > ul')[0].clientHeight;
            }, 200);
          }
        }, err => {
          console.error(err);
        });
    }
  }
}
