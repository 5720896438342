import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RepresentativesFilterModel } from 'src/app/models/Representatives/RepresentativesFilterModel';
import { SortEntityUserItems } from 'src/app/models/Representatives/SortEntityUserItems';
import { statusList } from 'src/app/models/users/statusList';
import { sortList } from 'src/app/models/users/sortList';
import { CustomRoles } from 'src/app/models/Representatives/CustomRoles';
import { RepresentativesModel } from 'src/app/models/Representatives/RepresentativesModel';
import { RepresentativesService } from 'src/app/services/representatives.service';
import { CreateUserComponent } from 'src/app/features/users/create-user/create-user.component';
import Swal from 'sweetalert2';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-representatives',
  templateUrl: './representatives.component.html'
})

export class RepresentativesComponent implements OnInit {

  dataLoading = false;
  showFilter = false;
  showSearch = false;

  statusItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.Active'), value: statusList.Active },
    { name: this.translate.instant('general.InActive'), value: statusList.InActive },
    { name: this.translate.instant('general.Suspended'), value: statusList.Suspended },
  ];

  customRoles = [
    { name: '', value: null },
    { name: this.translate.instant('CABs.Accountant'), value: CustomRoles.Accountant },
    { name: this.translate.instant('CABs.CAB Admin'), value: CustomRoles.CABAdmin },
    { name: this.translate.instant('CABs.CAB Communicator'), value: CustomRoles.CABCommunicator },
    { name: this.translate.instant('CABs.Certificate Management'), value: CustomRoles.CertificateManagement },
  ];

  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.City'), value: sortList.City },
    { name: this.translate.instant('general.Country'), value: sortList.Country },
    { name: this.translate.instant('general.Name'), value: sortList.Name },
    { name: this.translate.instant('general.Status'), value: sortList.Status },
    { name: this.translate.instant('general.Creation Date'), value: sortList.CreationDate },
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  filterModel: RepresentativesFilterModel = new RepresentativesFilterModel();

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private signalRService: SignalRServiceService,
    private service: RepresentativesService) {
      this.signalRService.handleNewRegistrationEvent(this, this.updateList);
    }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
  }

  updateList(context) {
    context.getAll(null);
  }

  sortRecords(selected) {
    this.filterModel.sortItems = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }

  statusFilter(selected) {
    this.filterModel.filter.status = Number(selected);
    this.getAll(null);
  }

  roleFilter(selected) {
    this.filterModel.filter.role = Number(selected);
    this.getAll(null);
  }

  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as RepresentativesModel;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '600px',
      data: { accountType: 2 }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }
  representativeStatus(representative, status) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        const obj = {
          id: representative.userId,
          userStatus: status
        };
        this.service.updateUserStatus(obj).subscribe(
          data => {
            this.getAll(null);
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            });
          }, err => {
            console.error(err);
            this.getAll(null);
            Swal.fire({
              title: this.translate.instant('messages.Error'),
              icon: 'error',
              confirmButtonText: this.translate.instant('messages.ok')
            });
          });
      }
    });
  }
}
