import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LookupsFilterModel } from 'src/app/models/lookupsManagement/LookupsFilterModel';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { oneRecord } from 'src/app/models/lookupsManagement/oneRecord';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CreateabtypesComponent } from './createabtypes/createabtypes.component';

@Component({
  selector: 'app-abtypes',
  templateUrl: './abtypes.component.html'
})
export class ABTypesComponent implements OnInit {

  dataLoading = false;
  showSearch = false;

  filterModel: LookupsFilterModel = new LookupsFilterModel();

  // mat table
  pageCount = 0;
  manualPage = 0;

  listDataObj: any;
  dataSource: oneRecord[] = [];

  selectedAbtype: oneRecord = new oneRecord();

  scopeType: number;

  constructor(private lookupManagementService: LookupManagementService,
              private translate: TranslateService,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute) { 
  }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.activatedRoute.queryParams.subscribe(params => {
        if (params.certificatescopeId) {
          this.filterModel.ParentId = params.certificatescopeId;
          this.getListCertificateScopes(null);
          this.scopeType = 1;
          this.listDataObj = undefined;
        } else if (params.scopesId) {
          this.filterModel.ParentId = params.scopesId;
          this.getListScopes(null);
          this.scopeType = 2;
          this.listDataObj = undefined;
        } else {
          this.getListABTypes(null);
          this.scopeType = 0;
          this.listDataObj = undefined;
        }
      }
    );
  }

  getAll(event) {
    if (this.scopeType === 0) {
      this.getListABTypes(event);
    } else if (this.scopeType === 1) {
      this.getListCertificateScopes(event);
    } else if (this.scopeType === 2) {
      this.getListScopes(event);
    }
  }

  getListABTypes(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListABTypes(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.listDataObj = data;
          this.dataSource = data.pageData as oneRecord[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          console.log('data is', data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  getListCertificateScopes(event) {
    this.dataSource = [];
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListCertificateScopes(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.listDataObj = data;
          this.dataSource = data.pageData as oneRecord[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          console.log('data is', data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  getListScopes(event) {
    this.dataSource = [];
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.lookupManagementService.getListScopes(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.listDataObj = data;
          this.dataSource = data.pageData as oneRecord[];
          this.filterModel.totalCount = data.totalCount;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
          console.log('data is', data);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  RemoveType(id) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.Yes delete it'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        if (this.scopeType === 0) {
          this.RemoveABType(id);
        } else if (this.scopeType === 1) {
          this.RemoveCertificateScope(id);
        } else if (this.scopeType === 2) {
          this.RemoveScope(id);
        }
      }
    });
  }

  RemoveCertificateScope(id) {
    this.lookupManagementService.RemoveCertificateScope(id).subscribe(
      data => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      },
      error => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Error'),
          text: error[0],
          icon: 'warning',
          confirmButtonText: this.translate.instant('messages.ok')
        });
    });
  }

  RemoveScope(id) {
    this.lookupManagementService.RemoveScope(id).subscribe(
      data => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      },
      error => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Error'),
          text: error[0],
          icon: 'warning',
          confirmButtonText: this.translate.instant('messages.ok')
        });
    });
  }

  RemoveABType(id) {
    this.lookupManagementService.RemoveABType(id).subscribe(
      data => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: '',
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      },
      error => {
        this.dataLoading = false;
        Swal.fire({
          title: this.translate.instant('messages.Error'),
          text: error[0],
          icon: 'warning',
          confirmButtonText: this.translate.instant('messages.ok')
        });
    });
  }

  // open create lang
  openCreateAbTypeDialog(scopeType) {
    const dialogRef = this.dialog.open(CreateabtypesComponent, {
      width: '530px',
      data: {
        selectedAbtype: null,
        scopeType,
        parentId: this.filterModel.ParentId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      }
    });
  }

  // open edit AbType
  openEditDialog(id) {
    if (this.scopeType === 1) {
      this.openEditCertificateScopeDialog(id, 1);
    } else if (this.scopeType === 2) {
      this.openEditScopeDialog(id, 2);
    } else {
      this.openEditAbTypeDialog(id, 0);
    }
  }

  openEditCertificateScopeDialog(id, scopeType) {
    this.lookupManagementService.GetCertificateScopeDetails(id).subscribe(
      data => {
        this.selectedAbtype = null;
        this.selectedAbtype = data as oneRecord;
        this.confgiEditDailog(this.selectedAbtype, scopeType);
      }, err => {
        console.error(err);
    });
  }

  openEditScopeDialog(id, scopeType) {
    this.lookupManagementService.GetScopeDetails(id).subscribe(
      data => {
        this.selectedAbtype = null;
        this.selectedAbtype = data as oneRecord;
        this.confgiEditDailog(this.selectedAbtype, scopeType);
      }, err => {
        console.error(err);
    });
  }

  openEditAbTypeDialog(id, scopeType) {
    this.lookupManagementService.GetABTypeDetails(id).subscribe(
      data => {
        this.selectedAbtype = null;
        this.selectedAbtype = data as oneRecord;
        this.confgiEditDailog(this.selectedAbtype, scopeType);
      }, err => {
        console.error(err);
    });
  }


  confgiEditDailog(selectedAbtype, scopeType) {
    const dialogRef = this.dialog.open(CreateabtypesComponent, {
      width: '530px',
      data: {
        selectedAbtype,
        scopeType,
        parentId: null,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      }
    });
  }

}
