import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { LanguageRow } from 'src/app/models/lookupsManagement/LanguageRow';

@Component({
  selector: 'app-create-language',
  templateUrl: './create-language.component.html'
})
export class CreateLanguageComponent implements OnInit {

  loading = false;

  languagesFormGroup: FormGroup;

  Language: LanguageRow = new LanguageRow();

  constructor(public dialogRef: MatDialogRef<CreateLanguageComponent>,
              @Inject(MAT_DIALOG_DATA) public selectedLanguage: LanguageRow = new LanguageRow(),
              private formBuilder: FormBuilder,
              private lookupManagementService: LookupManagementService) { }

  ngOnInit() {
    this.buildForms();
    // console.log('after open data', this.selectedLanguage);
  }

  buildForms() {
    this.languagesFormGroup = this.formBuilder.group({
      LangugeNameEng: ['', Validators.required],
      LangugeNameFr: ['', Validators.required]
    });

    if (this.selectedLanguage != null) {
      this.setValuesForm();
    }
  }

  setValuesForm() {
    this.languagesFormGroup.controls.LangugeNameEng.setValue(this.selectedLanguage.nameEn);
    this.languagesFormGroup.controls.LangugeNameFr.setValue(this.selectedLanguage.nameFr);
  }

  submitLanguage() {

    this.Language.nameEn = this.languagesFormGroup.controls.LangugeNameEng.value;
    this.Language.nameFr = this.languagesFormGroup.controls.LangugeNameFr.value;

    if (this.languagesFormGroup.valid) {
      this.loading = true;
      if (this.selectedLanguage == null) {
        // console.log(this.Language);
        this.createLanguage(this.Language);
      } else {
        // console.log('update');
        this.updatedLanguage(this.Language);
      }
    }

  }

  createLanguage(Language) {
    this.lookupManagementService.AddLanguage(Language).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  updatedLanguage(Language) {
    this.Language.id = this.selectedLanguage.id;
    this.lookupManagementService.UpdateLanguage(Language).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
