import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { User } from '../models/user/user';
import { ExpertRegister } from '../models/auth/Expert-register';
import { EenterpriseRegister } from '../models/auth/Enterprise-register';
import { Config } from '../models/config';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public authUser: any;
    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('adminUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email, password) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        // tslint:disable-next-line: max-line-length
        const code = encodeURIComponent('qWAAq+lmFSIcn2NMVRfcwPHzHivAGWdfbrgoniWvbL/1DZ+izmy4vWDDFW6nLZNn') + '&client_secret=' + encodeURIComponent('DPdU9mr4pHn3LYZu9jMfEvdP/fXCmsER5pABU4W4srApiFRGQHMvXEm/sBqcoVb1');
        // tslint:disable-next-line: max-line-length
        return this.http.post<any>(`${Config.loginUrl}`, 'username=' + email + '&password=' + password + '&grant_type=password&client_id=' + code, { headers })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                // console.log(user);
                this.authUser = user;
                this.getUserProfile();
                return user;
            }));
    }
    getUserProfile() {
        const user = this.currentUserValue;
        this.http.get(`${Config.apiUrl}/account/UserProfile`).pipe(first())
            .subscribe((data: any) => {
                user.email = data.email;
                user.fullName = data.name;
                localStorage.setItem('adminUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                window.location.reload();
                return user;
            },
            error => {
                //
            });
    }
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('adminUser');
        localStorage.removeItem('menu');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }
    checkUniqueEmail(email) {
        return this.http.get(`${Config.apiUrl}/account/CheckEmailExists?email=` + email);
    }
    forgetPassword(email) {
        return this.http.get(`${Config.apiUrl}/EntityUser/ForgetPassword?email=` + email);
    }
    resetPassword(userId, code, newPassword) {
        return this.http.post(`${Config.apiUrl}/EntityUser/ResetPassword`, { userId, token: code, newPassword });
    }
    ChangePassword(oldPassword, newPassword) {
        return this.http.post(`${Config.apiUrl}/EntityUser/ChangePassword`, { oldPassword, newPassword });
    }
    checkResetPasswordLink(userId, code) {
        return this.http.post(`${Config.apiUrl}/EntityUser/VerifyToken`, { userId, token: code });
    }
    confrimEmail(userId, code) {
        return this.http.get(`${Config.apiUrl}/EntityUser/ConfirmEmail?userId=` + userId + '&code=' + encodeURIComponent(code));
    }
    RegisterEntityUser(obj): any {
        return this.http.post(`${Config.apiUrl}/EntityUser/RegisterEntityUser`, obj);
    }
}
