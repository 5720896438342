import { Component, OnInit } from '@angular/core';

import { EntityFormComponent } from './entity-form/entity-form.component';
import { Country } from 'src/app/models/lookups/Country';
import { City } from 'src/app/models/lookups/City';
import { EntityListFilter } from 'src/app/models/entity/EntityListFilter';
import { EntityListData } from 'src/app/models/entity/EntityListData';
import { sortList } from 'src/app/models/entity/sortList';
import { EntityService } from 'src/app/services/entity.service';
import { LookupsService } from 'src/app/services/lookups.service';

import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html'
})
export class EntitiesComponent implements OnInit {
  dataLoading = false;
  showFilter = false;
  showSearch = false;

  countries: Country[] = [];
  cities: City[] = [];
  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.Name'), value: sortList.Name },
    { name: this.translate.instant('general.Country'), value: sortList.Country },
    { name: this.translate.instant('Entity.Certificates granted'), value: sortList.CertificateNumber }
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  filterModel: EntityListFilter = new EntityListFilter();

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private service: EntityService,
    private lookupsService: LookupsService
  ) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
    this.getCountries();
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as EntityListData;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  sortRecords(selected) {
    this.filterModel.sortItems = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openFormDialog(create) {
    const dialogRef = this.dialog.open(EntityFormComponent, {
      width: '600px',
      data: {create}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: (create) ?
            this.translate.instant('messages.Entity created successfully') : this.translate.instant('messages.Entity updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getAll(null);
        });
      }
    });
  }
}
