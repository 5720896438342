import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class LookupManagementService {

  constructor(private http: HttpClient) {
  }

  getListLanguages(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/ListLanguages`, filterModel);
  }
  AddLanguage(langObj): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/AddLanguage`, langObj);
  }
  RemoveLanguage(id) {
    return this.http.get(`${Config.apiUrl}/lookupManagement/RemoveLanguage/${id}`);
  }
  GetLanguageDetails(langId) {
    return this.http.get(`${Config.apiUrl}/lookupManagement/GetLanguageDetails/${langId}`);
  }
  UpdateLanguage(updatesLanguagerObj): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/UpdateLanguage`, updatesLanguagerObj);
  }

  // start new 
  getListCountries(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookups/ListCountries`, filterModel);
  }
  AddCountry(CountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/AddCountry`, CountryObj);
  }
  RemoveCountries(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveCountry/${id}`);
  }
  RemoveCity(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveCity/${id}`);
  }
  GetCountryDetails(counrtyId) {
    return this.http.get(`${Config.apiUrl}/lookups/GetCountryDetails/${counrtyId}`);
  }
  UpdateCountry(updatesCountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/UpdateCountry`, updatesCountryObj);
  }

  getListNationalities(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookups/ListNationalities`, filterModel);
  }

  AddNationality(CountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/AddNationality`, CountryObj);
  }

  UpdateNationality(updatesCountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/UpdateNationality`, updatesCountryObj);
  }

  GetNationalityDetails(counrtyId) {
    return this.http.get(`${Config.apiUrl}/lookups/GetNationalityDetails/${counrtyId}`);
  }

  RemoveNationality(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveNationality/${id}`);
  }
  
  
  getListABTypes(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookups/ListABTypes`, filterModel);
  }

  AddABType(CountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/AddABType`, CountryObj);
  }

  UpdateABType(updatesCountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/UpdateABType`, updatesCountryObj);
  }

  
  GetABTypeDetails(counrtyId) {
    return this.http.get(`${Config.apiUrl}/lookups/GetABTypeDetails/${counrtyId}`);
  }

  RemoveABType(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveABType/${id}`);
  }

  getListCertificateScopes(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookups/ListCertificateScopes`, filterModel);
  }

  getListScopes(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookups/ListScopes`, filterModel);
  }

  AddCertificateScope(CountryObj): any{
    return this.http.post(`${Config.apiUrl}/lookups/AddCertificateScope`, CountryObj);
  }

  AddScope(CountryObj): any{
    return this.http.post(`${Config.apiUrl}/lookups/AddScope`, CountryObj);
  }

  UpdateCertificateScope(updatesCountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/UpdateCertificateScope`, updatesCountryObj);
  }

  UpdateScope(updatesCountryObj): any {
    return this.http.post(`${Config.apiUrl}/lookups/UpdateScope`, updatesCountryObj);
  }

  
  GetCertificateScopeDetails(counrtyId) {
    return this.http.get(`${Config.apiUrl}/lookups/GetCertificateScopeDetails/${counrtyId}`);
  }

   
  GetScopeDetails(counrtyId) {
    return this.http.get(`${Config.apiUrl}/lookups/GetScopeDetails/${counrtyId}`);
  }

  RemoveCertificateScope(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveCertificateScope/${id}`);
  }

  RemoveScope(id) {
    return this.http.get(`${Config.apiUrl}/lookups/RemoveScope/${id}`);
  }
  // end new

  getListSectors(filterModel): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/ListSectors`, filterModel);
  }
  AddSector(SectorObj): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/AddSector`, SectorObj);
  }
  RemoveSector(id) {
    return this.http.get(`${Config.apiUrl}/lookupManagement/RemoveSector/${id}`);
  }
  RemoveSpecialization(id) {
    return this.http.get(`${Config.apiUrl}/lookupManagement/RemoveSpecialization/${id}`);
  }
  GetSectorDetails(sectorId) {
    return this.http.get(`${Config.apiUrl}/lookupManagement/GetSectorDetails/${sectorId}`);
  }
  UpdateSector(updatesSectorObj): any {
    return this.http.post(`${Config.apiUrl}/lookupManagement/UpdateSector`, updatesSectorObj);
  }

  getListFAQs(obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/ListFAQs`, obj);
  }
  AddFAQ(Obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/AddFAQ`, Obj);
  }
  UpdateFAQ(Obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/UpdateFAQ`, Obj);
  }
  GetFAQDetails(Id) {
    return this.http.get(`${Config.apiUrl}/homeManagement/GetFAQDetails/${Id}`);
  }
  RemoveFAQ(id) {
    return this.http.get(`${Config.apiUrl}/homeManagement/RemoveFAQ/${id}`);
  }

  getListTermsAndConditions(obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/ListTermsAndConditions`, obj);
  }
  AddTermsAndCondition(Obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/AddTermsAndCondition`, Obj);
  }
  UpdateTermsAndCondition(Obj): any {
    return this.http.post(`${Config.apiUrl}/homeManagement/UpdateTermsAndCondition`, Obj);
  }
  GetTermsAndConditionDetails(Id) {
    return this.http.get(`${Config.apiUrl}/homeManagement/GetTermsAndConditionDetails/${Id}`);
  }
  RemoveTermsAndCondition(id) {
    return this.http.get(`${Config.apiUrl}/homeManagement/RemoveTermsAndCondition/${id}`);
  }

  GetPolicy() {
    return this.http.get(`${Config.apiUrl}/homeManagement/GetPolicy`);
  }
  UpdatePolicy(obj) {
    return this.http.post(`${Config.apiUrl}/homeManagement/UpdatePolicy`, obj);
  }

  UpdateAppSettings(obj): any {
    const appObj = {
      settings : obj
    };
    return this.http.post(`${Config.apiUrl}/homeManagement/UpdateAppSettings`, appObj);
  }
  GetAppSettings() {
    return this.http.get(`${Config.apiUrl}/homeManagement/GetAppSettings`);
  }
}
