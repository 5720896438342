import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(private http: HttpClient) {
  }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/Certificate/ListCertificates`, filter);
  }
  save(objData, files) {
    let url;
    if (objData.certificateId) {
      url = '/Certificate/Edit';
    } else {
      url = '/Certificate/Add';
    }
    const formData = new FormData();
    files.forEach(file => {
      if (!file.attachmentId) {
        formData.append('file[]', file, file.name);
      }
    });
    formData.append('data', JSON.stringify(objData));
    return this.http.post(`${Config.apiUrl + url}`, formData);
  }
  getEntityByCiNumber(id) {
    return this.http.get(`${Config.apiUrl}/CertifiedEntity/GetCertifiedEntityDetailsByCiNumber?ciNumber=` + id);
  }
  checkUniqueCertificateNo(CerNo) {
    return this.http.get(`${Config.apiUrl}/Certificate/CheckCertificateNumber?text=` + CerNo);
  }
  getCertificateDetails(id) {
    return this.http.get(`${Config.apiUrl}/Certificate/GetCertificateDetails?CertificateId=` + id);
  }
  changeCertificateStatus(obj) {
    return this.http.post(`${Config.apiUrl}/Certificate/ChangeCertificateStatus`, obj);
  }
  AllowSearch(obj) {
    return this.http.post(`${Config.apiUrl}/Certificate/AllowSearch`, obj);
  }
  GetCertificateDetails(obj): any {
    return this.http.post(`${Config.apiUrl}/Certificate/GetCertificateDetailsWithCertificateNumber`, obj);
  }

}
