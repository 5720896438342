import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html'
})
export class ActivationComponent implements OnInit {
  code: string;
  userId: string;
  loading = true;
  isActivated = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userid');
      this.code = params.get('code');
      this.loading = true;
      this.authenticationService.confrimEmail(this.userId, this.code).subscribe(
        data => {
          this.loading = false;
          this.isActivated = true;
        },
        error => {
          this.loading = false;
          this.isActivated = false;
        });
    });
  }

}
