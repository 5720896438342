import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, AsyncValidatorFn, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { SectorModel } from 'src/app/models/lookupsManagement/SectorModel';
import { Details } from 'src/app/models/lookupsManagement/Details';
import { SectorDetails } from 'src/app/models/lookupsManagement/SectorDetails';
import { SectorUpdatedModel } from 'src/app/models/lookupsManagement/SectorUpdatedModel';
import { Specializations } from 'src/app/models/lookupsManagement/Specializations';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-sectors',
  templateUrl: './create-sectors.component.html'
})
export class CreateSectorsComponent implements OnInit {
  @ViewChild('sectorsForm', {static: false}) sectorsForm: NgForm;

  loading = false;

  sectorssFormGroup: FormGroup;

  sectorsEditable = true;
  validSectors = true;

  Sectors = [];
  listItemEng: string;
  listItemFr: string;
  mustAdded = false;
  SectorModel: SectorModel = new SectorModel();
  SectorUpdatedModel: SectorUpdatedModel = new SectorUpdatedModel();

  SpecializationsToDeleted: number[] = [];

  constructor(public dialogRef: MatDialogRef<CreateSectorsComponent>,
    private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public selectedSector: SectorDetails = new SectorDetails(),
              private formBuilder: FormBuilder,
              private lookupManagementService: LookupManagementService) { }

  ngOnInit() {
    this.buildForms();
    // console.log('after open data', this.selectedSector);
  }

  buildForms() {
    this.sectorssFormGroup = this.formBuilder.group({
      NameEng: ['', Validators.required],
      NameFr: ['', Validators.required]
    });

    if (this.selectedSector != null) {
      this.setValuesForm();
    }
  }

  setValuesForm() {
    this.sectorssFormGroup.controls.NameEng.setValue(this.selectedSector.nameEn);
    this.sectorssFormGroup.controls.NameFr.setValue(this.selectedSector.nameFr);
    this.Sectors = this.selectedSector.specializations;
  }

  submitSector() {

    if (this.Sectors.length === 0) {
      this.mustAdded = true;
    } else {
      this.mustAdded = false;
    }

    if (this.sectorssFormGroup.valid && this.Sectors.length !== 0) {
      this.loading = true;

      if (this.selectedSector == null) {
        // console.log('add');
        this.createSector();
      } else {
        // console.log('update');
        this.updatedSector();
      }

    }
  }

  createSector() {
    this.SectorModel.details = [];

    this.SectorModel.nameEn = this.sectorssFormGroup.controls.NameEng.value;
    this.SectorModel.nameFr = this.sectorssFormGroup.controls.NameFr.value;

    this.Sectors.forEach(element => {
      const currentEl = {nameEn: element.nameEn, nameFr: element.nameFr} as Details;
      this.SectorModel.details.push(currentEl);
    });

    this.lookupManagementService.AddSector(this.SectorModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  updatedSector() {

    this.SectorUpdatedModel.specializations = [];

    this.SectorUpdatedModel.id = this.selectedSector.id;
    this.SectorUpdatedModel.nameEn = this.sectorssFormGroup.controls.NameEng.value;
    this.SectorUpdatedModel.nameFr = this.sectorssFormGroup.controls.NameFr.value;
    // this.SectorUpdatedModel.SpecializationsToDeleted = this.SpecializationsToDeleted;

    this.Sectors.forEach(element => {
      const currentEl = {
        id : element.id ?  element.id : null,
        nameEn: element.nameEn,
        nameFr: element.nameFr
      } as Specializations;
      this.SectorUpdatedModel.specializations.push(currentEl);
    });

    // console.log('SectorUpdatedModel', this.SectorUpdatedModel);
    this.lookupManagementService.UpdateSector(this.SectorUpdatedModel).subscribe(
          data => {
            this.loading = false;
            this.dialogRef.close(true);
          }, err => {
            this.loading = false;
            console.error(err);
    });
  }
  addListItem() {
    if (this.listItemEng && this.listItemFr) {

      this.Sectors.push(
        {
          nameEn: this.listItemEng,
          nameFr: this.listItemFr,
        }
      );

      this.mustAdded = false;
      this.validSectors = true;
      this.listItemEng = this.listItemFr = undefined;
    }
  }

  removeSectorsItem(onesector, index) {
    this.SpecializationsToDeleted.push(onesector.id);
    if (onesector.id) {
      this.deleteCity(onesector, index);
    } else {
      this.Sectors.splice(index, 1);
    }
  }

  deleteCity(onesector, index) {
    if (this.selectedSector) {
      // console.log(onesector);
      Swal.fire({
        title: this.translate.instant('messages.Are you sure?'),
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('general.Yes delete it'),
        cancelButtonText: this.translate.instant('general.Cancel')
      }).then((result) => {
        if (result.value) {
          this.lookupManagementService.RemoveSpecialization(onesector.id).subscribe(
            data => {
              Swal.fire({
                title: this.translate.instant('messages.Success'),
                text: '',
                icon: 'success',
                confirmButtonText: this.translate.instant('messages.ok')
              }).then(() => {
                this.Sectors.splice(index, 1);
              });
            },
            error => {
              Swal.fire({
                title: this.translate.instant('messages.Error'),
                text: error[0],
                icon: 'warning',
                confirmButtonText: this.translate.instant('messages.ok')
              });
          });
        }
      });
    }
  }

  updateSectorsItem(index, event, inputType) {

    if (inputType === 'nameEn') {
      this.Sectors[index].nameEn = event.target.value;
    } else {
      this.Sectors[index].nameFr = event.target.value;
    }

  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  
}
