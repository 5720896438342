import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  loading = false;
  msgObj = {msg: '', type: ''};
  urlExpired = false;
  hideOldPassword = true;
  hidePassword = true;
  hideConfPassword = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#&._-])[A-Za-z\d$@$!%*?#&].{7,}$')
      ]],
      cPassword: ['', Validators.required]
    }, {validator: this.validateMatch });
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      const oldPassword = this.resetPasswordForm.controls.oldPassword.value;
      const newPassword = this.resetPasswordForm.controls.password.value;
      this.authenticationService.ChangePassword(oldPassword, newPassword).subscribe(
        data => {
          this.loading = false;
          this.msgObj.msg = this.translate.instant('auth.Password-updated-successfully');
          this.msgObj.type = 'success';
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: this.translate.instant('auth.Password-updated-successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          }).then((result) => {
            this.router.navigate(['/logout']);
          });
        },
        error => {
          this.loading = false;
          // this.msgObj.msg = this.translate.instant('forms.Try-again');
          this.msgObj.type = 'danger';
          if (error.code === -1) {
            this.msgObj.msg = this.translate.instant('auth.OldPasswordIncorrect');
          } else {
            this.msgObj.msg = this.translate.instant('forms.Try-again');
            // this.msgObj.msg = this.translate.instant('forms.email-not-correct');
          }
          // this.urlExpired = true;
        });
    }
  }

  validateMatch(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('cPassword').value;
    if (pass !== confirmPass) {
      group.get('cPassword').setErrors({ notMatch: true });
    } else {
      group.get('cPassword').setErrors(null);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.resetPasswordForm.controls[controlName].hasError(errorName);
  }
}
