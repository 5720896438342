import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { oneRecord } from 'src/app/models/lookupsManagement/oneRecord';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-countries',
  templateUrl: './create-countries.component.html'
})
export class CreateCountriesComponent implements OnInit {

  @ViewChild('countrtNameAr', { static: false }) countrtNameAr: NgModel;
  loading = false;

  CountryFormGroup: FormGroup;

  countryEditable = true;
  validcountry = true;

  country = [];
  mustAdded = false;
  listItemEng: string;
  listItemFr: string;

  countryModel: oneRecord = new oneRecord();

  CitiesToDeleted: number[] = [];

  CountryUpdatedModel: oneRecord = new oneRecord();

  constructor(public dialogRef: MatDialogRef<CreateCountriesComponent>,
              @Inject(MAT_DIALOG_DATA) public selectedCountry: oneRecord = new oneRecord(),
              private formBuilder: FormBuilder,
              private translate: TranslateService,
              private lookupManagementService: LookupManagementService) { }

  ngOnInit() {
    this.buildForms();
  }

  buildForms() {
    this.CountryFormGroup = this.formBuilder.group({
      NameEng: ['', Validators.required],
      NameAr: ['', [
          Validators.required,
          Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
        ]
      ],
    });

    if (this.selectedCountry != null) {
      this.setValuesForm();
    }
  }

  setValuesForm() {
    this.CountryFormGroup.controls.NameEng.setValue(this.selectedCountry.englishName);
    this.CountryFormGroup.controls.NameAr.setValue(this.selectedCountry.arabicName);
    this.country = this.selectedCountry.childs;
  }

  submitCountry() {
  
    debugger;
    if (this.country.length === 0) {
      this.mustAdded = true;
    } else {
      this.mustAdded = false;
    }

    if (this.CountryFormGroup.valid && this.country.length !== 0) {
      this.loading = true;
      if (this.selectedCountry == null) {
        this.createCountry();
      } else {
        this.updatedCountry();
      }
    }

  }

  createCountry() {

    this.countryModel.childs = [];

    this.countryModel.englishName = this.CountryFormGroup.controls.NameEng.value;
    this.countryModel.arabicName = this.CountryFormGroup.controls.NameAr.value;

    this.country.forEach(element => {
      const currentEl = {englishName: element.englishName, arabicName: element.arabicName} as oneRecord;
      this.countryModel.childs.push(currentEl);
    });


    this.lookupManagementService.AddCountry(this.countryModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });

  }

  updatedCountry() {
    debugger;
    this.CountryUpdatedModel.childs = [];

    this.CountryUpdatedModel.id = this.selectedCountry.id;
    this.CountryUpdatedModel.englishName = this.CountryFormGroup.controls.NameEng.value;
    this.CountryUpdatedModel.arabicName = this.CountryFormGroup.controls.NameAr.value;

    this.country.forEach(element => {
      const currentEl = {
        id : element.id ?  element.id : null,
        englishName: element.englishName,
        arabicName: element.arabicName
      } as oneRecord;
      this.CountryUpdatedModel.childs.push(currentEl);
    });

    this.lookupManagementService.UpdateCountry(this.CountryUpdatedModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  addListItem() {
    debugger;
    console.log(this.countrtNameAr.valid);
  
    if (this.listItemEng && this.listItemFr && this.countrtNameAr.valid) {

      this.country.push(
        {
          englishName: this.listItemEng,
          arabicName: this.listItemFr,
        }
      );
      this.mustAdded = false;
      this.validcountry = true;
      this.listItemEng = this.listItemFr = undefined;
    }
  }

  removeCountrysItem(city, index) {
    this.CitiesToDeleted.push(city.id);
    if (city.id) {
      this.deleteCity(city, index);
    } else {
      this.country.splice(index, 1);
    }
  }

  deleteCity(city, index) {
    if (this.selectedCountry) {
      // console.log(city);
      Swal.fire({
        title: this.translate.instant('messages.Are you sure?'),
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('general.Yes delete it'),
        cancelButtonText: this.translate.instant('general.Cancel')
      }).then((result) => {
        if (result.value) {
          this.lookupManagementService.RemoveCity(city.id).subscribe(
            data => {
              Swal.fire({
                title: this.translate.instant('messages.Success'),
                text: '',
                icon: 'success',
                confirmButtonText: this.translate.instant('messages.ok')
              }).then(() => {
                this.country.splice(index, 1);
              });
            },
            error => {
              Swal.fire({
                title: this.translate.instant('messages.Error'),
                text: error[0],
                icon: 'warning',
                confirmButtonText: this.translate.instant('messages.ok')
              });
          });
        }
      });
    }
  }

  updateCountrysItem(index, event, inputType) {

    if (inputType === 'nameEn') {
      this.country[index].englishName = event.target.value;
    } else {
      this.country[index].arabicName = event.target.value;
    }

  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }


  initName(name) {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
