import { Component, OnInit, Inject  } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PaymentRequestRecord } from 'src/app/models/paymentRequest/PaymentRequestRecord';
import { RespondToWalletRequest } from 'src/app/models/paymentRequest/RespondToWalletRequest';
import { PaymentRequestsService } from 'src/app/services/payment-requests.service';
import Swal from 'sweetalert2';

export interface DialogData {
  paymentId: number;
}

@Component({
  selector: 'app-payment-request-details',
  templateUrl: './payment-request-details.component.html'
})
export class PaymentRequestDetailsComponent implements OnInit {

  dataLoading = true;
  viewAccept = false;
  viewDecline = false;
  loading = false;

  recordsData: any = {};
  respondRequest = {} as RespondToWalletRequest;

  amountPaidControl = new FormControl('', Validators.required);
  ReasonDeclineControl = new FormControl('', Validators.required);

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData,
               private translate: TranslateService,
               public dialogRef: MatDialogRef<PaymentRequestDetailsComponent>,
               private service: PaymentRequestsService) { }

  ngOnInit() {
    // console.log(this.data.paymentId);
    this.respondRequest.entityWalletTransactionId = this.data.paymentId;
    this.GetEntityWalletDetails(this.data.paymentId);
  }

  GetEntityWalletDetails(paymentId) {
    this.dataLoading = true;
    this.service.GetEntityWalletDetails(paymentId).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as PaymentRequestRecord;
          // console.log(this.recordsData);
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  RespondToWalletRequest(responedType) {
    if (responedType) {
      this.viewAccept = !this.viewAccept;
      this.viewDecline = false;
      this.respondRequest.response = responedType;
    } else {
      this.viewDecline = !this.viewDecline;
      this.viewAccept = false;
      this.respondRequest.response = responedType;
    }
  }

  sendPaymentRequest() {
    // console.log(this.respondRequest);
    if (this.respondRequest.response) {
      this.respondRequest.amount = Number(this.amountPaidControl.value);
    } else {
      this.respondRequest.rejectionReason = this.ReasonDeclineControl.value;
    }

    if (this.respondRequest.amount || this.respondRequest.rejectionReason) {
      this.loading = true;
      this.service.RespondToWalletRequest(this.respondRequest).subscribe(
        data => {
          if (data) {
            this.loading = false;
            this.dialogRef.close(this.respondRequest.response ? 'accept' : 'reject');
          }
        },
        error => {
          this.dataLoading = false;
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: error.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
      });
    }
  }
}
