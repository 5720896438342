import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentRequestsService {

  refreshDuePaymentListSubject = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/WalletTransactions/ListEntityWalletTransactions`, filter);
  }
  GetEntityWalletDetails(id) {
    return this.http.get(`${Config.apiUrl}/WalletTransactions/GetEntityWalletDetails/${id}`);
  }
  RespondToWalletRequest(Respond) {
    return this.http.post(`${Config.apiUrl}/WalletTransactions/RespondToWalletRequest`, Respond);
  }
  ListDuePayments(filter): any {
    return this.http.post(`${Config.apiUrl}/DuePayments/ListDuePayments`, filter);
  }
  GetCBCurrentBalance() {
    return this.http.get(`${Config.apiUrl}/DuePayments/GetCBCurrentBalance`);
  }
  SubmitPayment(payment, files): any {
    const formData = new FormData();
    files.forEach(file => {
        // formData.append('file[]', file, file.name);
        if (file.attachmentId === undefined) { // send new files
            formData.append('file[]', file, file.name);
        }
    });
    formData.append('data', JSON.stringify(payment));
    return this.http.post(`${Config.apiUrl}/DuePayments/SubmitPayment`, formData);
  }
}
