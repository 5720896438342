import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {PaymentRequestDetailsComponent} from './payment-request-details/payment-request-details.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestsFilterModel } from 'src/app/models/paymentRequest/RequestsFilterModel';
import { PaymentRequest } from 'src/app/models/paymentRequest/PaymentRequest';
import { SortItems } from 'src/app/models/paymentRequest/SortItems';
import { PaymentSlipStatus } from 'src/app/models/paymentRequest/PaymentSlipStatus';
import { PaymentRequestsService } from 'src/app/services/payment-requests.service';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html'
})

export class PaymentRequestsComponent implements OnInit {

  @ViewChild('confirmationDialog', { static: true }) confirmationDialog: TemplateRef<any>;

  dataLoading = false;
  showFilter = false;
  showSearch = false;

  statusItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.WaitingforApproval'), value: PaymentSlipStatus.WaitingApproval },
    { name: this.translate.instant('general.ApprovedPaid'), value: PaymentSlipStatus.ApprovedByGAC },
    { name: this.translate.instant('general.Declined'), value: PaymentSlipStatus.RejectedByGAC },
  ];

  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.Created'), value: SortItems.Created },
    { name: this.translate.instant('general.Status'), value: SortItems.Status },
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  filterModel: RequestsFilterModel = new RequestsFilterModel();

  requestApproved: boolean;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private signalRService: SignalRServiceService,
    private service: PaymentRequestsService) {
      this.signalRService.handleNewPaymentInvoiceEvent(this, this.updateList);
    }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (Number(params.get('id')) > 0) {
        this.openPaymentRequestsDialog(params.get('id'));
      }
    });
    this.filterModel.pageSize = 10;
    this.getAll(null);
  }

  updateList(context) {
    context.getAll(null);
  }

  sortRecords(selected) {
    this.filterModel.sortItem = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }
  statusFilter(selected) {
    this.filterModel.filterItem.status = Number(selected);
    this.getAll(null);
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    // console.log(this.filterModel);
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as PaymentRequest;
          // console.log(this.recordsData);
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openPaymentRequestsDialog(walletTransactionId) {
    const dialogRef = this.dialog.open(PaymentRequestDetailsComponent, {
      width: '600px',
      data: {paymentId: walletTransactionId}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.requestApproved = result;
        this.openDialogWithTemplateRef(this.confirmationDialog);
        this.router.navigate(['/admin/payment']);
      }
    });
  }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.getAll(null);
    this.dialog.open(templateRef);
  }

}
