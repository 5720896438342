import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class RepresentativesService {

  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/EntityUser/ListCBUsers`, filter);
  }
  updateUserStatus(obj) {
    return this.http.post(`${Config.apiUrl}/EntityUser/UpdateEntityUserStatus`, obj);
  }
}
