import { Component, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateDiscussionComponent } from '../create-discussion/create-discussion.component';
import { DiscussionService } from 'src/app/services/discussion.service';
import { ConversationsFilterModal } from 'src/app/models/conversation/ConversationsFilterModal';
import { ConversationsModel } from 'src/app/models/conversation/ConversationsModel';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-discussions-list',
  templateUrl: './discussions-list.component.html'
})
export class DiscussionsListComponent implements OnInit {

  @Output() selectedMessageId = new EventEmitter<number>();

  filterModel: ConversationsFilterModal = new ConversationsFilterModal();

  dataLoading = false;

  recordsData: ConversationsModel = new ConversationsModel();
  recordsList = [];
  pageNumber = 0;
  pageCount: number;

  selectedId: number;
  constructor(
    public zone: NgZone,
    public dialog: MatDialog,
    private signalRService: SignalRServiceService,
    private service: DiscussionService
  ) { }

  ngOnInit() {
    this.filterModel.pageSize = 12;
    this.getDiscussions(true);
    this.signalRService.handleNewMessageEvent(this, this.updateRecords);
  }

  updateRecords(contxt, id) {
    contxt.zone.run(() => {
      contxt.getDiscussions(true);
    });
  }

  getNextPage() {
    if (this.pageNumber < this.pageCount - 1) {
      this.pageNumber += 1;
      this.getDiscussions(false);
    }
  }

  openDetails(message) {
    message.unReadMessagesCount = 0;
    this.selectedId = message.conversationId;
    this.selectedMessageId.emit(message.conversationId);
  }

  getDiscussions(update) {
    if (update) {
      this.recordsList = [];
    }
    this.dataLoading = true;
    this.filterModel.pageNumber = this.pageNumber;
    this.filterModel.ConversationId = null;
    this.service.getAll(this.filterModel).subscribe(
      result => {
        this.dataLoading = false;
        this.recordsData = result as ConversationsModel;
        this.pageCount = Math.ceil(result.totalCount /  this.filterModel.pageSize);
        this.recordsList = this.recordsList.concat(this.recordsData.pageData);
      }, err => {
        console.error(err);
    });
  }

  newDiscussion() {
    const dialogRef = this.dialog.open(CreateDiscussionComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDiscussions(true);
      }
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
}
