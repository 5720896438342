import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/Announcement/ListAnnouncement`, filter);
  }
  ListEntities(filter): any {
    return this.http.post(`${Config.apiUrl}/Entity/ListEntities`, filter);
  }

  LoadEntitiesByCountry() {
    return this.http.get(`${Config.apiUrl}/Entity/LoadEntitiesByCountry`);
  }

  LoadEntitiesForDiscussions() {
    return this.http.get(`${Config.apiUrl}/Entity/LoadEntitiesForDiscussions`);
  }
  
  addAnnouncements(obj) {
    return this.http.post(`${Config.apiUrl}/Announcement/Add`, obj);
  }
}
