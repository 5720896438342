import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material';

import { UsersService } from 'src/app/services/users.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LookupsService } from 'src/app/services/lookups.service';
import { City } from 'src/app/models/lookups/City';
import { Permission } from 'src/app/models/lookups/Permission';
import { Country } from 'src/app/models/lookups/Country';
import { User } from 'src/app/models/users/User';

import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  userId: any;
  cabId: any;
  accountType: number; // 1 => GAC & 2 => CAB
  isMyProfile: boolean;
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit {
  @ViewChild(MatSelectionList, {static: false}) Roles: MatSelectionList;
  dataLoading = false;
  loading = false;
  isSubmitted = false;
  togglePermission = false;

  details: any;

  formGroup: FormGroup;
  countries: Country[] = [];
  cities: City[] = [];
  Nationalities: Country[] = [];
  permissions: Permission[] = [];
  // rolesControl: any[] = [];

  emailLoader = false;
  isUpdateUser = false;

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private lookupsService: LookupsService,
    private service: UsersService
  ) { }

  ngOnInit() {
    if (this.data.userId || this.data.isMyProfile) {
      this.getDetails();
    } else {
      this.buildForm();
    }
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.data.userId).subscribe(
      data => {
        this.details = data;
        this.dataLoading = false;
        this.isUpdateUser = true;
        this.togglePermission = true;
        // console.log('data', data);

        this.buildForm();
        this.bindDataToUpdateAccount();
      },
      error => {
        this.dataLoading = false;
        // console.log('error');
      });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      enName: ['', [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arName: ['', [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      email: ['', [
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
          Validators.required
        ], [this.uniqueEmailValidator()]
      ],
      country: ['', Validators.required],
      city: ['', Validators.required],
      nationality: ['', Validators.required],
      mobile: ['', [
        Validators.required,
        Validators.pattern('^[+]?[0-9]{11,50}$')
        ]
      ],
      userType: [0, Validators.required],
      permissions: [[], Validators.required]
    });
    this.getCountries();
    this.getNationalities();
    if (this.data.accountType === 1) {
      this.getPermissions();
    } else {
      this.getRoles();
    }
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
        if (this.isUpdateUser) {
          this.getCities(this.details.country.id);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }
  getNationalities() {
    this.lookupsService.GetNationalities().subscribe(
      data => {
        this.Nationalities = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getRoles() {
    this.lookupsService.GetRoles().subscribe(
      data => {
        this.permissions = data as Permission[];
        if (this.Roles) {
          this.Roles.selectionChange.subscribe((s: MatSelectionListChange) => {
            if (Number(this.formGroup.controls.userType.value) === 1) {
              this.Roles.deselectAll();
              s.option.selected = true;
            }
          });
        }
      }, err => {
        console.error(err);
      });
  }
  getPermissions() {
    this.lookupsService.GetPermissions().subscribe(
      data => {
        this.permissions = data as Permission[];
      }, err => {
        console.error(err);
      });
  }
  updateCities(value) {
    this.formGroup.controls.city.setValue('');
    this.getCities(value);
  }
  ////////////////////////////
  bindDataToUpdateAccount() {
    const user = this.details;
    this.formGroup.controls.enName.setValue(user.englishFullName);
    this.formGroup.controls.arName.setValue(user.arabicFullName);
    this.formGroup.controls.email.setValue(user.email);
    this.formGroup.controls.country.setValue(user.country.id);
    this.formGroup.controls.city.setValue(user.city.id);
    this.formGroup.controls.mobile.setValue(user.mobile);
    this.formGroup.controls.nationality.setValue(user.nationality.id);
    this.formGroup.controls.userType.setValue(user.userType);
    this.selectPermissions();
  }
  selectPermissions() {
    const selectedPermission = [];
    this.details.permissions.forEach(permission => {
      selectedPermission.push(permission.id);
    });
    this.formGroup.controls.permissions.setValue(selectedPermission);
    // console.log(selectedPermission);
  }
  permissionlog() {
    console.log(this.formGroup.controls.permissions.value);
  }

  uniqueEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) { this.emailLoader = true; }
      return this.authenticationService.checkUniqueEmail(control.value)
        .pipe(
          map(res => {
            this.emailLoader = false;
            if (this.isUpdateUser) { return; }
            if (res) {
              return { emailTaken: true };
            }
          })
        );
    };
  }
  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  saveUser() {
    this.isSubmitted = true;
    if (this.formGroup.valid && this.permissions.length) {
      this.loading = true;
      let user;
      user = {
        email: this.formGroup.controls.email.value,
        arabicFullName: this.formGroup.controls.arName.value,
        englishFullName: this.formGroup.controls.enName.value,
        phone: this.formGroup.controls.mobile.value,
        cityid: this.formGroup.controls.city.value,
        nationalityId: this.formGroup.controls.nationality.value
      } as User;
      if (this.data.accountType === 1) {
        user.permissions = this.formGroup.controls.permissions.value;
      } else {
        user.userType = this.formGroup.controls.userType.value;
        user.roles = this.formGroup.controls.permissions.value;
      }
      if (this.isUpdateUser) {
        user.entityUserId = this.details.id;
      }
      if (this.data.cabId) {
        user.entityId = this.data.cabId;
      }
      this.service.saveUser(user, this.data.accountType).subscribe(
        () => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: (!this.isUpdateUser) ?
              this.translate.instant('messages.User created successfully') : this.translate.instant('messages.Updated successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok'),
          });
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok'),
          });
      });
    }
  }
}
