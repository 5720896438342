import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateDiscussionComponent } from './create-discussion/create-discussion.component';
import { DiscussionDetailsComponent } from './discussion-details/discussion-details.component';
import { DiscussionService } from 'src/app/services/discussion.service';
import { ConversationsFilterModal } from 'src/app/models/conversation/ConversationsFilterModal';
import { ConversationsModel } from 'src/app/models/conversation/ConversationsModel';
import { DiscussionsListComponent } from './discussions-list/discussions-list.component';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
@Component({
  selector: 'app-discussions',
  templateUrl: './discussions.component.html'
})
export class DiscussionsComponent implements OnInit {

  filterModel: ConversationsFilterModal = new ConversationsFilterModal();

  dataLoading = true;
  discissionsResult: any;
  recordsData: ConversationsModel = new ConversationsModel();
  pageNumber = 0;

  @ViewChild('discussionsDetails', {static: false}) discussionsDetailsDetails: DiscussionDetailsComponent;
  @ViewChild('discussionsListing', {static: false}) discussionsListing: DiscussionsListComponent;

  constructor(
    public zone: NgZone,
    public dialog: MatDialog,
    private signalRService: SignalRServiceService,
    private service: DiscussionService
  ) { }

  ngOnInit() {
    this.filterModel.pageSize = 12;
    this.getDiscussions();
    this.signalRService.handleNewMessageEvent(this, this.updateRecords);
    this.signalRService.handleNewMessageEvent(this, this.updateConversation);
  }

  updateRecords(context, id) {
    context.zone.run(() => {
      context.getDiscussions();
    });
  }

  updateConversation(context, id) {
    context.zone.run(() => {
      if (id === context.discussionsDetailsDetails.messageId) {
        context.setMessageId(id);
      }
    });
  }

  setMessageId(id: number) {
    // console.log(id);
    this.discussionsDetailsDetails.messageId = id;
    this.discussionsDetailsDetails.GetConversationMessages(id, true);
  }

  getDiscussions() {
      this.dataLoading = false;
      this.filterModel.pageNumber = this.pageNumber;
      this.filterModel.ConversationId = null;
      this.service.getAll(this.filterModel).subscribe(
        result => {
          this.dataLoading = false;
          this.recordsData = result as ConversationsModel;
          // // console.log(this.recordsData);
        }, err => {
          console.error(err);
      });
  }

  newDiscussion() {
    const dialogRef = this.dialog.open(CreateDiscussionComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDiscussions();
      }
    });
  }
}
