import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Country } from 'src/app/models/lookups/Country';
import { City } from 'src/app/models/lookups/City';
import { Branch } from 'src/app/models/entity/Branch';
import { LookupsService } from 'src/app/services/lookups.service';

@Component({
  selector: 'app-entity-branch-form',
  templateUrl: './entity-branch-form.component.html'
})
export class EntityBranchFormComponent implements OnInit {
  branchFormGroup: FormGroup;
  countries: Country[] = [];
  cities: City[] = [];

  @Input() recored: Branch = new Branch();

  @Output() newRecored = new EventEmitter<Branch>();
  @Output() deleteItem = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
        if (this.recored.countryId) {
          this.getCities(this.recored.countryId);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }

  buildForm() {
    this.getCountries();
    this.branchFormGroup = this.formBuilder.group({
      enName: [this.recored.englishName, [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arName: [this.recored.arabicName, [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      country: [this.recored.countryId, Validators.required],
      city: [this.recored.cityId, Validators.required],
      enAddress: [this.recored.englishAddress, Validators.required],
      arAddress: [this.recored.arabicAddress],
      commercialNo: [this.recored.commercialIdentificationNumber],
      phone: [this.recored.phone, [Validators.pattern('^[+]?[0-9]{11,50}$')]]
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  submitRecored() {
    if (this.branchFormGroup.valid) {
      this.recored.englishName = this.branchFormGroup.controls.enName.value;
      this.recored.arabicName = this.branchFormGroup.controls.arName.value;
      this.recored.countryId = this.branchFormGroup.controls.country.value;
      this.recored.cityId = this.branchFormGroup.controls.city.value;
      this.recored.englishAddress = this.branchFormGroup.controls.enAddress.value;
      this.recored.arabicAddress = this.branchFormGroup.controls.arAddress.value;
      this.recored.commercialIdentificationNumber = this.branchFormGroup.controls.commercialNo.value;
      this.recored.phone = this.branchFormGroup.controls.phone.value;
      this.newRecored.emit(this.recored);
    }
  }
}
