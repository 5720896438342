export class Branch {
    certifiedEntityId: number;
    certifiedEntitySiteId: number;
    arabicName: string;
    englishName: string;
    arabicAddress: string;
    englishAddress: string;
    phone: string;
    countryId: number;
    cityId: number;
    commercialIdentificationNumber: string;
}
