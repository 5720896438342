import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entity } from 'src/app/models/entity/Entity';
import { EntityService } from 'src/app/services/entity.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateEntityDialogComponent } from './update-entity-dialog/update-entity-dialog.component';
import { UpdateBranchesDialogComponent } from './update-branches-dialog/update-branches-dialog.component';
import { UpdateRepresentativeDialogComponent } from './update-representative-dialog/update-representative-dialog.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-entity-details',
  templateUrl: './entity-details.component.html'
})
export class EntityDetailsComponent implements OnInit {
  dataLoading = false;
  representativesLoading = false;
  branchesLoading = false;

  entityId: any;
  details: Entity = new Entity();
  branchesData: any;
  representativesData: any;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: EntityService,
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.entityId = params.get('id');
        this.getDetails();
        this.getRepresentatives();
        this.getBranches();
      }
    });
  }

  getDetails() {
    this.dataLoading = true;
    this.service.GetCertifiedEntitiesDetails(this.entityId).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.details = data as Entity;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  getRepresentatives() {
    this.representativesLoading = true;
    this.service.ListCertifiedEntityRepsentative(this.entityId).subscribe(
      data => {
        this.representativesLoading = false;
        if (data) {
          this.representativesData = data;
        }
      },
      error => {
        this.representativesLoading = false;
    });
  }

  getBranches() {
    this.branchesLoading = true;
    this.service.ListCertifiedEntityBranches(this.entityId).subscribe(
      data => {
        this.branchesLoading = false;
        if (data) {
          this.branchesData = data;
        }
      },
      error => {
        this.branchesLoading = false;
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openFormDialog() {
    const dialogRef = this.dialog.open(UpdateEntityDialogComponent, {
      width: '600px',
      data: {record: this.details}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Entity updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getDetails();
        });
      }
    });
  }

  openBranchesDialog() {
    const dialogRef = this.dialog.open(UpdateBranchesDialogComponent, {
      width: '600px',
      data: {
        certifiedEntityId: this.entityId,
        branches: this.branchesData.pageData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Entity branches updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getBranches();
        });
      }
    });
  }

  openRepresantativeDialog() {
    const dialogRef = this.dialog.open(UpdateRepresentativeDialogComponent, {
      width: '600px',
      data: {
        certifiedEntityId: this.entityId,
        representatives: this.representativesData.pageData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Entity representative updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getRepresentatives();
        });
      }
    });
  }
}
