import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(private http: HttpClient) {
  }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/CertifiedEntity/ListCertifiedEntities`, filter);
  }
  AddEntity(entity, fileToUpload) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(entity));
    if (fileToUpload && fileToUpload.name) {
      formData.append('logo', fileToUpload, fileToUpload.name);
    }

    return this.http.post(`${Config.apiUrl}/CertifiedEntity/Add`, formData);
  }
  EditEntity(entity, fileToUpload) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(entity));
    if (fileToUpload && fileToUpload.name) {
      formData.append('logo', fileToUpload, fileToUpload.name);
    }
    return this.http.post(`${Config.apiUrl}/CertifiedEntity/Edit`, formData);
  }
  GetCertifiedEntitiesDetails(id): any {
    return this.http.get(`${Config.apiUrl}/CertifiedEntity/GetCertifiedEntitiesDetails?certifiedEntityId=` + id);
  }
  ListCertifiedEntityRepsentative(id): any {
    const filter = {
      PageSize: 5,
      PageNumber: 0,
      CertifiedEntityId: id
    };
    return this.http.post(`${Config.apiUrl}/CERepresentative/ListCertifiedEntityRepsentative`, filter);
  }
  ListCertifiedEntityBranches(id): any {
    const filter = {
      PageSize: 5,
      PageNumber: 0,
      CertifiedEntityId: id
    };
    return this.http.post(`${Config.apiUrl}/CESite/ListCertifiedEntitySite`, filter);
  }
  CEBranchesEdit(branchesData): any {
    return this.http.post(`${Config.apiUrl}/CESite/Edit`, branchesData);
  }
  CERepresentativesEdit(representativesData): any {
    return this.http.post(`${Config.apiUrl}/CERepresentative/Edit`, representativesData);
  }
  CheckCINumber(CENum) {
    return this.http.get(`${Config.apiUrl}/CertifiedEntity/CheckCINumber?ciNumber=${CENum}`);
  }
}
