export class Representative {
    certifiedEntitySiteId: number;
    certifiedEntitiesRepresentativesId: number;
    arabicFullName: string;
    englishFullName: string;
    arabicTitle: string;
    englishTitle: string;
    nationalityId: number;
    countryId: number;
    cityId: number;
    email: string;
    phone: string;
    mobileNumber: string;
}
