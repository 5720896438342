import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncementsService } from 'src/app/services/announcements.service';
import { AnnouncementsFilterModel } from 'src/app/models/Announcements/AnnouncementsFilterModel';
import { AnnouncementsModel } from 'src/app/models/Announcements/AnnouncementsModel';
import { CreateAnnouncementComponent } from './create-announcement/create-announcement.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html'
})

export class AnnouncementsComponent implements OnInit {

  dataLoading = false;
  showFilter = false;
  showSearch = false;

  filterModel: AnnouncementsFilterModel = new AnnouncementsFilterModel();

  // entity
  filterEntitiy = {
    pageNumber: 0,
    pageSize: 999,
  };
  entityListing: any = [];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  // showMore = false;
  constructor(public dialog: MatDialog,
              public zone: NgZone,
              private service: AnnouncementsService,
              private translate: TranslateService,
              private signalRService: SignalRServiceService,
              ) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
    this.ListEntities();
    this.signalRService.handleNewAnnouncementEvent(this, this.updateList);
  }

  updateList(contxt) {
    contxt.zone.run(() => {
      contxt.getAll(null);
    });
  }

  ListEntities() {
    this.service.ListEntities(this.filterEntitiy).subscribe(
      data => {
        if (data) {
          this.entityListing = data.pageData as [];
        }
      },
      error => {
      });
  }
  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as AnnouncementsModel;
          this.recordsData.pageData.map((item) => {
            item.messageToExpand = false;
            item.showMore = false;
          });
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  togglemessageToExpand(index) {
    this.recordsData.pageData.forEach((element, currentIndex) => {
      if (index === currentIndex) {
        element.messageToExpand = !element.messageToExpand;
      } else {
        element.messageToExpand = false;
      }
    });
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }

  makeFilter() {
    this.getAll(null);
  }

  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openPaymentRequestsDialog() {
    const dialogRef = this.dialog.open(CreateAnnouncementComponent, {
      width: '600px',
      data: { data: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title:  this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Announcementcreatedsuccessfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok'),
        }).then(() => {
           this.getAll(null);
        });
      }
    });
  }

}
