import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class DiscussionService {

  constructor(private http: HttpClient) { }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/conversation/ListConversations`, filter);
  }
  CreateConversation(enityId) {
    return this.http.get(`${Config.apiUrl}/conversation/CreateConversation/${enityId}`);
  }
  GetConversationMessages(filter): any {
    return this.http.post(`${Config.apiUrl}/conversation/GetConversationMessages`, filter);
  }
  CreateMessage(msgObj) {
    return this.http.post(`${Config.apiUrl}/conversation/CreateMessage`, msgObj);
  }
}
