import { Injectable, EventEmitter, DebugElement } from '@angular/core';
declare var $: any;
import { Config } from '../models/config';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRServiceService {
  private messageHub: any;
  SignalrConnection: any;
  signalReceived = new EventEmitter<any>();
  constructor(private authService: AuthenticationService) {

  }

  buildConnection() {
    const connection = $.hubConnection(Config.signalRUrl);
    this.messageHub = connection.createHubProxy('GACHub');
    const token = this.authService.currentUserValue.access_token;
    $.signalR.ajaxDefaults.headers = { Authorization: 'Bearer ' + token };
    connection.start()
      .done(() => {
        console.log('connection started ..........');
      })
      .fail(() => {
        console.log('connection failed');
      });
  }

  disconnectConnection() {
    this.messageHub.disconnect(() => {
      console.log('Server has disconnected');
    });
  }

  handleChangeNotificationCountEvent(context, handleFunc) {
    this.messageHub.on('changeNotificationCount', (count) => {
      handleFunc(context, count);
    });
  }

  handleNewRegistrationEvent(context, handleFunc) {
    this.messageHub.on('newRegistration', (id) => {
      handleFunc(context, id);
    });
  }

  handleNewPaymentInvoiceEvent(context, handleFunc) {
    this.messageHub.on('newPaymentInvoice', (id) => {
      handleFunc(context, id);
    });
  }

  handleRespondPaymentEvent(context, handleFunc) {
    this.messageHub.on('respondPayment', (id) => {
      handleFunc(context, id);
    });
  }

  handleNewMessageEvent(context, handleFunc) {
    this.messageHub.on('newMessage', (id) => {
      handleFunc(context, id);
    });
  }

  handleNewAnnouncementEvent(context, handleFunc) {
    this.messageHub.on('newAnnouncement', () => {
      handleFunc(context);
    });
  }

  handleUpdateCBProfileEvent(context, handleFunc) {
    this.messageHub.on('updateCBProfile', (id) => {
      handleFunc(context, id);
    });
  }
}
