import { Component, OnInit } from '@angular/core';
import { Color, Label } from 'ng2-charts';
import { User } from 'src/app/models/user/user';
import { Country } from 'src/app/models/lookups/Country';
import { LookupsService } from 'src/app/services/lookups.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  currentUser = new User();
  public pieChartData: number[] = [];
  chartLabels: Label[] = [];
  chartOptions = {
    responsive: true
  };
  chartLegend = true;
  chartColors: Color[] = [
    {
      backgroundColor: ['#F8D7DA', '#C3E6CB', '#5A74A1', '#2BA7C8']
    },
  ];

  chartLoader = true;
  staticsLoader = true;
  statics = [];
  countries: Country[] = [];
  cabs = [];
  chartData: any;
  filter = {
    CABIds: null,
    countryIds: null,
    dateFrom: null,
    dateTo: null
  };
  constructor(
    private authenticationService: AuthenticationService,
    private lookupsService: LookupsService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.getCountries();
    this.getCabs();
    this.getStatics();
    this.getChartData();
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getCabs() {
    this.lookupsService.LoadEntitiesBySelectedCountry(this.filter.countryIds).subscribe(
      data => {
        this.cabs = data as any;
      }, err => {
        console.error(err);
      });
  }

  getStatics() {
    this.staticsLoader = true;
    this.lookupsService.GetDashboardHeader().subscribe(
      data => {
        this.staticsLoader = false;
        if (data) {
          this.statics = data as [];
        }
      },
      error => {
        this.staticsLoader = false;
    });
  }

  getChartData() {
    this.chartLoader = true;
    this.lookupsService.GetDashboardDetails(this.filter).subscribe(
      data => {
        this.chartLoader = false;
        if (data) {
          this.chartLabels = data['labels'];
          this.pieChartData = data['data'];
        }
      },
      error => {
        this.chartLoader = false;
    });
  }

}
