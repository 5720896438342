import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        public activeRout: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            let canAccess = false;
            const menuList = JSON.parse(localStorage.getItem('menu')) || [];
            menuList.push('/login');
            menuList.push('/admin/myprofile');
            menuList.push('/admin/changepassword');
            if (location.pathname !== '/' && location.pathname !== '/gaccert/') {
                menuList.forEach(item => {
                    if (location.pathname.includes(item) === true) {
                        canAccess = true;
                    }
                });
                if (canAccess) {
                    return true;
                }
                this.router.navigate(['/admin/myprofile']);
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
