import { sortList } from './sortList';
export class EntityListFilter {
    pageSize: number;
    pageNumber: number;
    sortItems: sortList;
    searchText: string;
    cabEntityId: number;
    filter = {} as {
        name: string;
        commercialIdentificationNumber: number;
        countries: number[];
        cities: number[];
        certificateFrom: string;
        certificateTo: string;
    };
}
