import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class CabsService {

  constructor(private http: HttpClient) {
  }

  getAll(filter): any {
    return this.http.post(`${Config.apiUrl}/Entity/ListEntities`, filter);
  }
  save(objData, logo) {
    let url;
    if (objData.entityId) {
      url = '/Entity/EditEntity';
    } else {
      url = '/Entity/CreateCBEntity';
    }
    const formData = new FormData();
    if (logo && logo.name) {
      formData.append('logo', logo, logo.name);
    }
    formData.append('data', JSON.stringify(objData));
    return this.http.post(`${Config.apiUrl + url}`, formData);
  }
  checkUniqueCommercialNo(commercialNo) {
    return this.http.get(`${Config.apiUrl}/Entity/ValidateCommercialIdentificationNumber?commercialIdentificationNumber=` + commercialNo);
  }
  GetEntitiesDetails(id) {
    return this.http.get(`${Config.apiUrl}/Entity/GetEntitiesDetails?entityId=` + id);
  }
  GetEntityProfileDetails() {
    return this.http.get(`${Config.apiUrl}/Entity/GetEntityProfileDetails`);
  }
  GetEntitiesByScope(id) {
    return this.http.get(`${Config.apiUrl}/Entity/GetEntitiesByScope?cityId=&scopeId=5&aBTypeId=` + id);
  }
  GetEntityByCommercialIdentificationNumber(CINo) {
    return this.http.get(`${Config.apiUrl}/Entity/GetEntityByCommercialIdentificationNumber?commercialIdentificationNumber=` + CINo);
  }
  LoadEntitiesByCountry(obj) {
    return this.http.post(`${Config.apiUrl}/Entity/LoadEntitiesByCountry`, obj);
  }
  GetWalletHistory(id) {
    return this.http.get(`${Config.apiUrl}/WalletTransactions/GetWalletHistory?id=` + id);
  }
  GetEntityUsersRepresentatives(id) {
    return this.http.get(`${Config.apiUrl}/EntityUser/GetEntityUsersRepresentatives/${id}`);
  }
  UpdateEntityUserStatus(obj) {
    return this.http.post(`${Config.apiUrl}/EntityUser/UpdateEntityUserStatus`, obj);
  }
  UpdateEntityStatus(obj) {
    return this.http.post(`${Config.apiUrl}/Entity/UpdateEntityStatus`, obj);
  }
  RenewEntity(obj) {
    return this.http.post(`${Config.apiUrl}/Entity/RenewEntity`, obj);
  }
  SubmitPayment(obj, file) {
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('data', JSON.stringify(obj));
    return this.http.post(`${Config.apiUrl}/DuePayments/SubmitPayment`, formData);
  }
  GetDuePaymentIds() {
    return this.http.get(`${Config.apiUrl}/DuePayments/GetDuePaymentIds`);
  }
}
