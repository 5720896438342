import { Component, OnInit } from '@angular/core';

import { SearchCertificateModel } from 'src/app/models/certificates/SearchCertificateModel';
import { SearchCertificateFilterModel } from 'src/app/models/certificates/SearchCertificateFilterModel';
import { LoginType } from 'src/app/models/certificates/LoginType';

import { CertificateService } from 'src/app/services/certificate.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-search-cab',
  templateUrl: './search-cab.component.html'
})
export class SearchCABComponent implements OnInit {

  dataLoading = false;
  isEmpty = false;
  searchLimit = false;
  makeSearch = false;

  filterModel: SearchCertificateFilterModel = new SearchCertificateFilterModel();

  recordsData: SearchCertificateModel = new SearchCertificateModel();


  constructor(
    private service: CertificateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let loginId;
    loginId = localStorage.getItem('loginId');
    if (!loginId) {
      loginId = new Date().toString();
      localStorage.setItem('loginId', loginId);
    }
    this.filterModel.fromQr = false;

    this.filterModel.loginType = LoginType.Web;
    this.filterModel.loginId = loginId;

    this.route.queryParams.subscribe(params => {
      if (params.certificateNumber) {
        this.filterModel.certificateNumber = params.certificateNumber;
        this.filterModel.fromQr = true;
        this.getAll();
      }
    });
  }

  getAll() {
    this.recordsData = new SearchCertificateModel();
    if (!this.filterModel.certificateNumber) { return; }
    this.dataLoading = true;
    this.makeSearch = false;
    this.isEmpty = false;
    this.searchLimit = false;
    this.service.GetCertificateDetails(this.filterModel).subscribe(
      data => {
        if (data) {
          this.dataLoading = false;
          this.makeSearch = true;
          this.isEmpty = false;
          this.searchLimit = false;
          this.recordsData = data as SearchCertificateModel;
          // console.log('recordsData', this.recordsData);
        }
      },
      error => {
        this.dataLoading = false;
        this.makeSearch = true;
        if (error.code === -4) {
          this.searchLimit = true;
        } else {
          this.isEmpty = true;
        }
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }
}
