import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Representative } from 'src/app/models/entity/Representative';
import { EntityService } from 'src/app/services/entity.service';

export interface DialogData {
  certifiedEntityId: number;
  representatives: Representative[];
}

@Component({
  selector: 'app-update-representative-dialog',
  templateUrl: './update-representative-dialog.component.html'
})
export class UpdateRepresentativeDialogComponent implements OnInit {
  loading = false;

  activeRepresentativeIndex = 0;
  representatives: Representative[] = [new Representative()];
  representativesError = false;
  deletedIds = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UpdateRepresentativeDialogComponent>,
    private service: EntityService
  ) { }

  ngOnInit() {
    if (this.data.representatives && this.data.representatives.length) {
      this.representatives = JSON.parse(JSON.stringify(this.data.representatives));
    }
  }

  saveRepresentatives() {
    this.representativesError = false;
    if (this.representatives.length !== 2 || this.validateRepresentatives()) {
      this.representativesError = true;
      return;
    }
    this.loading = true;
    const representativesData = {
      toBeDeleted: this.deletedIds,
      items: this.representatives
    };
    this.service.CERepresentativesEdit(representativesData).subscribe(
      () => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
      });
  }

  ///////////////////////////////////////
  openNewRepresentative() {
    if (this.representatives[this.representatives.length - 1].englishFullName) {
      this.representatives.push(new Representative());
    }
    this.activeRepresentativeIndex = this.representatives.length - 1;
  }
  toggleRepresentativeSet(index) {
    if (this.activeRepresentativeIndex === index) {
      this.activeRepresentativeIndex = undefined;
    } else {
      this.activeRepresentativeIndex = index;
    }
  }
  pushNewRepresentative(representative) {
    this.representatives[this.activeRepresentativeIndex] = representative;
    this.activeRepresentativeIndex = undefined;
  }
  deleteRepresentative() {
    if (this.representatives[this.activeRepresentativeIndex].certifiedEntitiesRepresentativesId) {
      this.deletedIds.push(this.representatives[this.activeRepresentativeIndex].certifiedEntitiesRepresentativesId);
    }
    this.representatives.splice(this.activeRepresentativeIndex, 1);
    if (!this.representatives.length) {
      this.representatives.push(new Representative());
      this.activeRepresentativeIndex = this.representatives.length - 1;
    } else {
      this.activeRepresentativeIndex = undefined;
    }
  }
  validateRepresentatives() {
    if (this.representatives.length === 2 && this.representatives[1].englishFullName) {
      return false;
    }
    return true;
  }
}
