export class Config {
    public static localhost = 'http://localhost:4099/';
    public static devUrl = 'https://dev.expertapps.com.sa';
    public static testUrl = 'https://test.expertapps.com.sa';
    public static demodevUrl = 'https://dev.mosandah.com.sa';
    public static demoUrl = 'https://demo.mosandah.com.sa';
    public static proUrl = 'https://mscertificatesapi.gac.org.sa';

    public static currentEnv = Config.proUrl;
    // public static baseUrl = Config.currentEnv + '/gaccertfe';
    public static baseUrl = Config.currentEnv;

    public static apiUrl = Config.baseUrl + '/api';
    public static loginUrl = Config.baseUrl + '/oauth/token';
    public static adminApiUrl = Config.baseUrl + '/api/admin';
    // public static signalRUrl = Config.currentEnv + '/gaccertslr';
    public static signalRUrl = 'https://mscertificatesslr.gac.org.sa';
    // public static signalRUrl =
    //     (Config.currentEnv !== Config.demoUrl) ? Config.currentEnv + '/egacslr' : Config.currentEnv + '/gaccertslr';
    public static siteTitle = 'GAC';

    public static frontUrl = Config.currentEnv + '/gaccert';
    // public static frontUrl = Config.currentEnv;
}
